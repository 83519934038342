import React, { type PropsWithChildren } from 'react'
import type { Shift } from '@sevenrooms/core/domain'
import type { UseForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { RecommendationTip } from '@sevenrooms/core/ui-kit/core'
import { DateRangePicker, DayOfWeekPicker } from '@sevenrooms/core/ui-kit/form'
import { Box, Flex, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ShiftSelectorComponent } from '@sevenrooms/mgr-access-rules-slideout/components/Schedule/components'
import { messages } from './locales/experienceStepperFlow.locales'
import type { CreateExperienceFromTemplateFormData } from './CreateExperienceFromTemplateForm.zod'

export function NewAccessRuleScheduling({
  form,
  allShifts,
}: {
  form: UseForm<z.ZodType<CreateExperienceFromTemplateFormData>>
  allShifts: Shift[] | undefined
}) {
  const { formatMessage } = useLocales()
  const { field, getValues } = form
  const values = getValues()

  return (
    <VStack pt="xxl" pb="xxl" spacing="l" width="496px" data-test="create-new-access-rule-scheduling-step-content">
      <Text textStyle="h2" textAlign="center">
        {formatMessage(messages.newAccessRuleSchedulingStepTitle, {
          offerName: (
            <Text textStyle="h2" fontWeight="bold">
              "{values.name}"
            </Text>
          ),
        })}
      </Text>
      <VStack>
        <AccessRuleDetailsFieldWrapper label={formatMessage(messages.newAccessRuleSchedulingDate)} noBottomBorderRadius>
          <HStack pt="s" pb="xxs">
            <Flex flex="1">
              <Text textStyle="body1">{formatMessage(messages.newAccessRuleSchedulingStartDate)}</Text>
            </Flex>
            <Flex flex="1">
              <Text textStyle="body1">{formatMessage(messages.newAccessRuleSchedulingEndDate)}</Text>
            </Flex>
          </HStack>
          <Box pb="m">
            <DateRangePicker
              data-test="date-range-picker"
              id="date-range-picker"
              field={field.prop('accessRule').prop('schedule').prop('dateRange')}
              infinite
              showToLabel={false}
            />
          </Box>
        </AccessRuleDetailsFieldWrapper>
        <RecommendationTip
          header={formatMessage(messages.recommendationTipHelpfulTip)}
          description={formatMessage(messages.newAccessRuleSchedulingHelpfulTip)}
          headerIcon="helpfulTip"
          noTopBorderRadius
        />
      </VStack>
      <AccessRuleDetailsFieldWrapper label={formatMessage(messages.newAccessRuleSchedulingDayOfTheWeek)}>
        <Box pt="s" pb="none">
          <DayOfWeekPicker
            data-test="day-of-week-picker"
            name="day-of-week-picker"
            field={field.prop('accessRule').prop('schedule').prop('selectedDays')}
            hideSelectAllDays
          />
        </Box>
      </AccessRuleDetailsFieldWrapper>
      <AccessRuleDetailsFieldWrapper label={formatMessage(messages.newAccessRuleSchedulingShifts)}>
        <Box pb="m" pt="s">
          {allShifts && <ShiftSelectorComponent field={field.prop('accessRule').prop('schedule')} shifts={allShifts} />}
        </Box>
      </AccessRuleDetailsFieldWrapper>
    </VStack>
  )
}

function AccessRuleDetailsFieldWrapper({
  label,
  noBottomBorderRadius,
  children,
}: PropsWithChildren<{ label: string; noBottomBorderRadius?: boolean }>) {
  return (
    <VStack
      borderWidth="s"
      borderBottomLeftRadius={noBottomBorderRadius ? 'none' : 's'}
      borderBottomRightRadius={noBottomBorderRadius ? 'none' : 's'}
      borderTopLeftRadius="s"
      borderTopRightRadius="s"
      borderColor="dividerLines"
      pt="m"
      pl="m"
      pr="m"
    >
      <Text textStyle="body1Bold">{label}</Text>
      {children}
    </VStack>
  )
}
