import * as React from 'react'
import { useMemo } from 'react'
import { useGetAccessRulesListQuery } from '@sevenrooms/core/api'
import type { AccessRuleToShow } from '@sevenrooms/core/domain'
import type { UseForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { VStack, NotificationBanner } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AccessRuleSelector } from '../../experiences/components/ExperienceAccessRules/AccessRuleSelector'
import { AccessRuleSelectorLocales } from '../../experiences/components/ExperienceAccessRules/AccessRuleSelector.locales'
import { messages } from './locales/experienceStepperFlow.locales'
import type { CreateExperienceFromTemplateFormData } from './CreateExperienceFromTemplateForm.zod'

interface LinkToExistingAccessRuleProps {
  form: UseForm<z.ZodType<CreateExperienceFromTemplateFormData>>
  venueId: string
}
export function LinkToExistingAccessRule({ form, venueId }: LinkToExistingAccessRuleProps) {
  const { formatMessage } = useLocales()

  const { data: accessRuleData, isLoading } = useGetAccessRulesListQuery({ venueId })
  const accessRules = useMemo<AccessRuleToShow[]>(
    () =>
      accessRuleData
        ? accessRuleData
            .filter(rule => !rule.experienceID)
            .map(rule => {
              const days = rule.date ? rule.date.formatSWeek() : rule.dayOfWeekName?.join(', ') || ''

              const dateRange = rule.date
                ? `${rule.date.formatNYearNMonthNDay()} - ${rule.date.formatNYearNMonthNDay()}`
                : `${rule.startDate?.formatNYearNMonthNDay()} - ${rule.endDate?.formatNYearNMonthNDay() || 'Indefinite'}`

              let time = rule.startEndTimesByShiftDisplay.flatMap(i => i.times.join(' - ')).join(', ')
              if (rule.specificTimesDisplay?.length) {
                time = rule.specificTimesDisplay?.join(', ')
              } else if (rule.startTimeDisplay) {
                time = `${rule.startTimeDisplay} - ${rule.endTimeDisplay}`
              }

              const partySize = rule.partySizeMin
                ? `${rule.partySizeMin}-${rule.partySizeMax}`
                : formatMessage(AccessRuleSelectorLocales.followShrift)

              return {
                ...rule,
                days,
                dateRange,
                time,
                partySize,
              }
            })
        : [],
    [accessRuleData, formatMessage]
  )

  const { getValues, setValue } = form
  const values = getValues()

  return (
    <VStack spacing="l" width="496px" alignItems="center" data-test="link-existing-access-rule-step-content">
      <Text textStyle="h1" fontWeight="bold" textAlign="center">
        {formatMessage(messages.linkToExistingAccessRuleStepTitle, {
          offerName: (
            <Text textStyle="h1" fontWeight="bold">
              "{values?.name}"
            </Text>
          ),
        })}
      </Text>
      <VStack spacing="s" width="100%" borderWidth="s" borderRadius="s" borderColor="dividerLines" p="lm">
        <Text textStyle="body1Bold">{formatMessage(messages.selectAccessRulesToLinkTheOfferTo)}</Text>
        <VStack>
          <AccessRuleSelector
            accessRules={accessRules}
            selectedAccessRuleIds={[]}
            setSelectedAccessRuleIds={rules => {
              setValue('linkedAccessRuleIds', rules)
            }}
            sorted
          />
          {!isLoading && !accessRules.length && (
            <NotificationBanner
              mt="s"
              type="warning"
              iconName="VMSWeb-warning"
              showIcon
              content={formatMessage(AccessRuleSelectorLocales.allAccessRulesAreAlreadyLinked)}
            />
          )}
        </VStack>
      </VStack>
    </VStack>
  )
}
