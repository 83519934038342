import { useCallback, useMemo, useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { SelectFilter, SelectTarget } from '@sevenrooms/core/ui-kit/core'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { BaseSection, HStack, Spreadsheet, type DataTableColumn } from '@sevenrooms/core/ui-kit/layout'
import { Link } from '@sevenrooms/core/ui-kit/typography'
import { getReservationSearchURL } from '@sevenrooms/marketing/utils'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { oneTimeEmailCenterMessages } from '../../../locales'

interface Reservation {
  date: string
  time: string
  name: string
  covers: number
  id: number
  createdDate: string
  spend: string
  email: string
  reservationId: string
}

const reservations = [
  {
    id: 1,
    date: 'Jan 23, 2024',
    time: '7:00 PM',
    name: 'Craig Torff',
    covers: 3,
    createdDate: 'Jan 16, 2024',
    spend: '$100.00',
    email: 'craig.torff@example.com',
    reservationId: 'ahZzfnNldmVucm9vbXMtc2VjdXJlLXFhcicLEhtuaWdodGxvb3BfUmVzZXJ2YXRpb25BY3R1YWwYwbasjrKKBgw',
  },
  {
    id: 2,
    date: 'Jan 23, 2024',
    time: '5:45 PM',
    name: 'Tatiana Levin',
    covers: 4,
    createdDate: 'Jan 16, 2024',
    spend: '',
    email: 'tatiana.levin@example.com',
    reservationId: 'ahZzfnNldmVucm9vbXMtc2VjdXJlLXFhcicLEhtuaWdodGxvb3BfUmVzZXJ2YXRpb25BY3R1YWwYzp_KirKKBgw',
  },
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any

export function ReservationsTable({ isPOSActivated, reservationsCount }: { isPOSActivated?: boolean; reservationsCount: number }) {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const [searchValue, setSearchValue] = useState('')

  const columns = useMemo<DataTableColumn<Reservation>[]>(() => {
    const commonColumns: DataTableColumn<Reservation>[] = [
      {
        header: formatMessage(oneTimeEmailCenterMessages.reservationsTableHeaderDate),
        width: '120px',
        accessor: 'date',
        render: 'date',
      },
      {
        header: formatMessage(oneTimeEmailCenterMessages.reservationsTableHeaderTime),
        width: '120px',
        accessor: 'time',
        render: 'time',
      },
      {
        header: formatMessage(oneTimeEmailCenterMessages.reservationsTableHeaderName),
        accessor: 'name',
        render: 'name',
      },
      {
        header: formatMessage(oneTimeEmailCenterMessages.reservationsTableHeaderCovers),
        accessor: 'covers',
        render: 'covers',
      },
      {
        header: formatMessage(oneTimeEmailCenterMessages.reservationsTableHeaderCreatedDate),
        accessor: 'createdDate',
        width: '120px',
        render: 'createdDate',
      },
    ]

    if (isPOSActivated) {
      const spendColumn: DataTableColumn<Reservation> = {
        header: formatMessage(oneTimeEmailCenterMessages.reservationsTableHeaderSpend),
        accessor: 'spend',
        render: 'spend',
      }

      commonColumns.push(spendColumn)
    }

    return commonColumns
  }, [isPOSActivated, formatMessage])

  const onRowClick = useCallback((row: Reservation) => {
    window.SvrManager.ActualSlideout.viewActual(row.reservationId)
  }, [])

  const filteredData = useMemo(
    () =>
      reservations.filter(
        res => res.name.toLowerCase().includes(searchValue.toLowerCase()) || res.email.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [searchValue]
  )

  const url = getReservationSearchURL(venue.urlKey, null, null, null)

  return (
    <BaseSection maxWidth="none">
      <HStack justifyContent="space-between" alignItems="center" p="m" borderBottomColor="borders">
        <Link isExternal to={url} fontSize="xl" fontWeight={700}>
          {reservationsCount} {formatMessage(oneTimeEmailCenterMessages.reservationsTab)} &nbsp;
          <Icon name="VMSWeb-open-in-new" />
        </Link>
        <SelectTarget width="368px">
          <SelectFilter
            placeholder={formatMessage(oneTimeEmailCenterMessages.reservationsTableSearch)}
            selectedValue={searchValue || null}
            searchable
            onChange={event => {
              setSearchValue(event.currentTarget.value)
            }}
          />
        </SelectTarget>
      </HStack>
      <Spreadsheet onRowClick={onRowClick} columns={columns} data={filteredData} noBorder hoverOnRows />
    </BaseSection>
  )
}
