import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import { formatToOptionsList } from 'mgr/pages/single-venue/marketing/utils/custom-autotags'
import CustomAutotagConfigButton from './CustomAutotagConfigButton'
import CustomAutotagConfigDivider from './CustomAutotagConfigDivider'
import CustomAutotagConfigValueSwitch from './CustomAutotagConfigValueSwitch'
import { CustomAutotagSearchDropdownSingle } from './CustomAutotagSearchDropdownSingle'
import { InlineText } from './CustomAutotagShared'

const ConfigEditListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ConfigItemWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-flow: wrap;
`

const ConfigItemIndexWrapper = styled.div`
  width: 20px;
  padding: 20px 0;
`

const ConfigItemIndex = styled.span`
  color: #555b63;
  font-size: 14px;
`

const ConfigItemBlockWrapper = styled.div`
  flex-grow: 1;
  background-color: #f7f7f7;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 0;
`

const ConfigInnerItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  margin-bottom: 20px;
`

const CustomAutotagConfigButtonWrapper = styled.div`
  width: 100%;
  ${props => props.css};
`

const ChainBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  flex-wrap: wrap;
`

const MainBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  width: 100%;
  flex: 1;
  padding: 0 44px 0 0;
  box-sizing: border-box;
  flex-wrap: wrap;
  & > div:first-child {
    width: 260px;
  }
`

const RemoveConditionButton = styled.button`
  background-color: transparent;
  border: none;
  color: #e95857;
  padding: 0;
  width: 40px;
  height: 35px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  margin-left: auto;
  position: absolute;
  right: 0;
`

const MAX_CONDITIONS = 50

class CustomAutotagConfigInnerItem extends React.PureComponent {
  constructor(props) {
    super(props)
    const { index, innerIndex, removeConfigItem, updateConfigState, resetConfigState } = this.props
    this.removeConfigItem = removeConfigItem.bind(this, index, innerIndex)
    this.updateConfigState = updateConfigState.bind(this, index, innerIndex)
    this.resetConfigState = resetConfigState.bind(this, index, innerIndex)
  }

  updateConfigStateCondition = condition => this.updateConfigState({ ...this.props.innerItemState, condition })

  updateConfigStateOperator = operator => this.updateConfigState({ ...this.props.innerItemState, operator })

  updateConfigStateValue = value => this.updateConfigState({ ...this.props.innerItemState, value })

  updateConfigStateChainItem = (chainItemIdx, updateProp) => {
    const { innerItemState, index, innerIndex, updateConfigStateChainItem } = this.props
    const prevState = _.get(innerItemState, ['chain', chainItemIdx])
    const updateState = { ...prevState, ...updateProp }
    updateConfigStateChainItem(index, innerIndex, chainItemIdx, updateState)
  }

  updateConfigStateChainItemOperator = (chainItemIdx, operator) => this.updateConfigStateChainItem(chainItemIdx, { operator })

  updateConfigStateChainItemValue = (chainItemIdx, value) => this.updateConfigStateChainItem(chainItemIdx, { value })

  getValueObjectTypeAndOperators = (conditionKey, operatorKey) => {
    const { conditionsMap, valueObjectsMap, operatorsList } = this.props.customAutotagObjects
    const conditionObj = conditionsMap[conditionKey]
    return _.reduce(
      conditionObj.value_object_types,
      (accum, valueObjectType) => {
        const valueObject = valueObjectsMap[valueObjectType]
        if (!accum[0] && _.includes(valueObject.operator_types, operatorKey)) {
          // eslint-disable-next-line no-param-reassign
          accum[0] = valueObjectType
        }
        const operators = _.filter(operatorsList, operatorItem => _.includes(valueObject.operator_types, _.keys(operatorItem)[0]))
        // eslint-disable-next-line no-param-reassign
        accum[1] = _.concat(accum[1], operators)
        return accum
      },
      [null, []]
    )
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevProps.isGlobal && this.props.isGlobal) {
      if (prevProps.innerItemState.condition === 'MARKETING_OPT_IN_SOURCE') {
        this.resetConfigState(prevProps.index, prevProps.innerIndex)
      }
    }
  }

  render() {
    const { viewID, index, innerIndex, innerItemState, customAutotagObjects, isGlobal, isConfigStateValid, configStateLength } = this.props
    const showRemoveItemButton = configStateLength > 1
    const removeButton = showRemoveItemButton && (
      <RemoveConditionButton id={`sr-custom-remove-${index}-${innerIndex}`} onClick={this.removeConfigItem}>
        &#10005;
      </RemoveConditionButton>
    )

    const optionsToFilter = ['VISIT_VENUE_NAME']
    if (isGlobal) {
      optionsToFilter.push('MARKETING_OPT_IN_SOURCE')
    }
    const _conditionOptions = formatToOptionsList(customAutotagObjects.conditionsList, 'condition_type', 'display_name')
    const conditionOptions = _conditionOptions.filter(item => !optionsToFilter.includes(item.key) || item.key === innerItemState.condition)

    const innerItemID = `sr-custom-${index}-${innerIndex}`
    if (!innerItemState.condition) {
      const isValid = isConfigStateValid || configStateLength > 1
      return (
        <ConfigInnerItemWrapper>
          <CustomAutotagSearchDropdownSingle
            id={`${innerItemID}-condition`}
            key={`CONDITION_${innerItemState.condition}_${isGlobal}`}
            viewID={viewID}
            isSearchable
            options={conditionOptions}
            selectedValue={innerItemState.condition}
            placeholder="Select an attribute"
            onChange={this.updateConfigStateCondition}
            isValid={isValid}
            invalidMessage="You are required to pick at least one attribute"
            customWrapper={css`
              width: 300px;
            `}
          />
          {removeButton}
        </ConfigInnerItemWrapper>
      )
    }

    const [valueObjectType, operatorsList] = this.getValueObjectTypeAndOperators(innerItemState.condition, innerItemState.operator)
    const operatorOptions = formatToOptionsList(operatorsList, 'operator_type', 'display_name')
    return (
      <ConfigInnerItemWrapper>
        <MainBlock>
          <CustomAutotagSearchDropdownSingle
            id={`${innerItemID}-condition`}
            key={`CONDITION_${innerItemState.condition}_${isGlobal}`}
            viewID={viewID}
            isSearchable
            options={conditionOptions}
            selectedValue={innerItemState.condition}
            placeholder="Select an attribute"
            onChange={this.updateConfigStateCondition}
          />
          <CustomAutotagSearchDropdownSingle
            id={`${innerItemID}-operator`}
            viewID={viewID}
            key={`CONDITION_${innerItemState.condition}_OPERATOR_${innerItemState.operator}`}
            isSearchable={false}
            options={operatorOptions}
            selectedValue={innerItemState.operator}
            onChange={this.updateConfigStateOperator}
          />
          <CustomAutotagConfigValueSwitch
            id={`${innerItemID}-value`}
            viewID={viewID}
            locale={this.props.locale}
            value={innerItemState.value}
            fieldType={valueObjectType}
            onChange={this.updateConfigStateValue}
            isGlobal={isGlobal}
            isConfigStateValid={isConfigStateValid}
            invalidMessage="You are required to set a value"
            key={`CONDITION_${innerItemState.condition}_VALUE_OBJECT_${valueObjectType}`}
          />
        </MainBlock>
        {removeButton}
        {_.reduce(
          innerItemState.chain,
          (accum, chainItemState, idx) => {
            if (optionsToFilter.includes(chainItemState.condition)) {
              return accum
            }
            const [chainValueObjectType, chainOperatorsList] = this.getValueObjectTypeAndOperators(
              chainItemState.condition,
              chainItemState.operator
            )
            const chainOperatorOptions = formatToOptionsList(chainOperatorsList, 'operator_type', 'display_name')
            const condition = customAutotagObjects.conditionsList.find(cnd => chainItemState.condition in cnd)
            const { condition_name: conditionName } = condition[chainItemState.condition] ?? {}
            const chainPrefix = conditionName && (
              <InlineText
                key={`CONDITION_${chainItemState.condition}_NAME_${innerItemState.operator}_${innerItemID}_${idx}`}
                css={`
                  min-width: 200px;
                `}
              >
                and {conditionName}
              </InlineText>
            )
            const operatorComponent = (
              <CustomAutotagSearchDropdownSingle
                id={`${innerItemID}-${idx}-operator`}
                key={`CONDITION_${chainItemState.condition}_OPERATOR_${innerItemState.operator}_${innerItemID}_${idx}`}
                viewID={viewID}
                isSearchable={false}
                options={chainOperatorOptions}
                selectedValue={chainItemState.operator}
                onChange={this.updateConfigStateChainItemOperator.bind(this, idx)}
                customWrapper={css`
                  width: 205px;
                `}
              />
            )
            const valueComponent = (
              <CustomAutotagConfigValueSwitch
                id={`${innerItemID}-${idx}-value`}
                viewID={viewID}
                locale={this.props.locale}
                value={chainItemState.value}
                fieldType={chainValueObjectType}
                onChange={this.updateConfigStateChainItemValue.bind(this, idx)}
                isGlobal={isGlobal}
                key={`CONDITION_${chainItemState.condition}_VALUE_OBJECT_${chainValueObjectType}_${innerItemID}_${idx}`}
              />
            )
            return _.concat(accum, [
              // eslint-disable-next-line react/jsx-key
              <ChainBlock>
                {chainPrefix} {operatorComponent} {valueComponent}
              </ChainBlock>,
            ])
          },
          []
        )}
      </ConfigInnerItemWrapper>
    )
  }
}

const CustomAutotagConfigItem = ({
  viewID,
  index,
  itemState,
  updateConfigState,
  updateConfigStateChainItem,
  addConfigItem,
  removeConfigItem,
  customAutotagObjects,
  isGlobal,
  isConfigStateValid,
  configStateLength,
  resetConfigState,
}) => {
  const boundAddConfigItem = addConfigItem.bind(this, index)
  const showAddButton = configStateLength < MAX_CONDITIONS
  return (
    <ConfigItemWrapper>
      <ConfigItemIndexWrapper>
        <ConfigItemIndex>{index + 1}.</ConfigItemIndex>
      </ConfigItemIndexWrapper>
      <ConfigItemBlockWrapper>
        {_.map(itemState, (innerItemState, innerItemIndex) => {
          const hasNext = !!itemState[innerItemIndex + 1]
          return (
            <div key={innerItemIndex}>
              <CustomAutotagConfigInnerItem
                key={innerItemIndex}
                viewID={viewID}
                index={index}
                innerIndex={innerItemIndex}
                innerItemState={innerItemState}
                removeConfigItem={removeConfigItem}
                updateConfigState={updateConfigState}
                updateConfigStateChainItem={updateConfigStateChainItem}
                customAutotagObjects={customAutotagObjects}
                isGlobal={isGlobal}
                isConfigStateValid={isConfigStateValid}
                configStateLength={configStateLength}
                resetConfigState={resetConfigState}
              />
              {hasNext && (
                <CustomAutotagConfigDivider
                  text="OR"
                  css={`
                    padding: 0 20px;
                  `}
                />
              )}
              {!hasNext && showAddButton && (
                <CustomAutotagConfigButtonWrapper>
                  <CustomAutotagConfigButton
                    id={`sr-custom-or-${index}-${innerItemIndex}`}
                    onClick={boundAddConfigItem}
                    text={'Add "OR" condition'}
                  />
                </CustomAutotagConfigButtonWrapper>
              )}
            </div>
          )
        })}
      </ConfigItemBlockWrapper>
    </ConfigItemWrapper>
  )
}

const CustomAutotagConfigEdit = ({
  viewID,
  configState,
  customAutotagObjects,
  updateConfigState,
  updateConfigStateChainItem,
  addOuterConfigRow,
  addConfigItem,
  removeConfigItem,
  isGlobal,
  isConfigStateValid,
  resetConfigState,
}) => {
  const configStateLength = _.flatten(configState).length
  const showAddButton = configStateLength < MAX_CONDITIONS
  return (
    <ConfigEditListWrapper>
      {_.map(configState, (itemState, itemIndex) => {
        const hasNext = !!configState[itemIndex + 1]
        return (
          <div key={itemIndex}>
            <CustomAutotagConfigItem
              index={itemIndex}
              itemState={itemState}
              updateConfigState={updateConfigState}
              updateConfigStateChainItem={updateConfigStateChainItem}
              addConfigItem={addConfigItem}
              removeConfigItem={removeConfigItem}
              customAutotagObjects={customAutotagObjects}
              isGlobal={isGlobal}
              isConfigStateValid={isConfigStateValid}
              configStateLength={configStateLength}
              viewID={viewID}
              resetConfigState={resetConfigState}
            />
            {hasNext && (
              <CustomAutotagConfigDivider
                text="AND"
                css={`
                  padding: 0 0 0 20px;
                `}
              />
            )}
            {!hasNext && showAddButton && (
              <CustomAutotagConfigButtonWrapper
                css={`
                  margin-top: 25px;
                  margin-left: 20px;
                `}
              >
                <CustomAutotagConfigButton id={`sr-custom-and-${itemIndex}`} onClick={addOuterConfigRow} text={'Add "AND" condition'} />
              </CustomAutotagConfigButtonWrapper>
            )}
          </div>
        )
      })}
    </ConfigEditListWrapper>
  )
}

export default CustomAutotagConfigEdit
