import { useMemo } from 'react'
import type { VenueProfile } from '@sevenrooms/core/domain'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'

export type VenueInformationFormData = ZodSchema<ReturnType<typeof useVenueInformationForm>>
export type VenueInformationFormFields = Field<VenueInformationFormData>
export type VenueInformationFieldKey =
  | 'address'
  | 'crossStreet'
  | 'city'
  | 'state'
  | 'postalCode'
  | 'country'
  | 'phoneNumber'
  | 'contactEmail'
  | 'website'
  | 'menuLink'
  | 'gmapsLink'
  | 'reservationWidgetLink'

const urlFieldOptional = z.string().url().nullable().or(z.literal(''))

export const useVenueInformationForm = () =>
  useMemo(
    () =>
      z.object({
        address: z.string(),
        crossStreet: z.string(),
        city: z.string(),
        state: z.string(),
        postalCode: z.string(),
        country: z.string(),
        phoneNumber: z.string(),
        contactEmail: z.string().email().nullable().or(z.literal('')),
        website: urlFieldOptional,
        menuLink: urlFieldOptional,
        gmapsLink: urlFieldOptional,
        reservationWidgetLink: urlFieldOptional,
        averageSpendPerCover: z.number().nullable().default(null),
        averageSpendPerOrder: z.number().nullable().default(null),
      }),
    []
  )

export const getVenueInformationFormDefaultValues = (source: VenueProfile): VenueInformationFormData => ({
  address: source.address,
  crossStreet: source.crossStreet,
  city: source.city,
  state: source.state,
  postalCode: source.postalCode,
  country: source.country,
  phoneNumber: source.phoneNumber,
  contactEmail: source.contactEmail,
  website: source.website,
  menuLink: source.menuLink,
  gmapsLink: source.gmapsLink,
  reservationWidgetLink: source.reservationWidgetLink,
  averageSpendPerCover: source.averageSpendPerCover,
  averageSpendPerOrder: source.averageSpendPerOrder,
})
