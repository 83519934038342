import styled from 'styled-components'
import type { PropsWithChildren, ReactNode } from 'react'

export const ExpandedButton = styled.button`
  color: #0abcc2;
  height: 40px;
  width: 100%;
  text-align: start;
  border-color: none;
  background: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
`
const Wrapper = styled.div`
  margin-top: 16px;
`

export interface AutotagExpanderProps {
  title: ReactNode
  setExpanded: (expand: boolean) => void
  expanded?: boolean
}

export const AutotagExpander = ({ children, title, setExpanded, expanded = false }: PropsWithChildren<AutotagExpanderProps>) => (
  <Wrapper>
    {expanded ? (
      children
    ) : (
      <ExpandedButton onClick={() => setExpanded(true)} data-test="autotag-expanded-button">
        {title}
      </ExpandedButton>
    )}
  </Wrapper>
)
