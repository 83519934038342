import { skipToken } from '@reduxjs/toolkit/dist/query'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { type CampaignActivityItem, type OneTimeEmailCampaignType, useGetClientTagGroupsQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useDestination } from '@sevenrooms/core/navigation'
import { differenceInCalendarDays } from '@sevenrooms/core/timepiece'
import { useDebounceEffect } from '@sevenrooms/core/ui-kit/hooks'
import { Grid, BaseSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { campaignCenterMessages, EmailCampaignPreview } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { Box, Link, Skeleton, Stack } from '@sevenrooms/react-components'
import { routes } from '@sevenrooms/routes'
import { useGetEmailCampaignRecipientDetailsQuery, useGetEmailCampaignRecipientsByTagsQuery } from '../../hooks'
import { CampaignPerformanceClicksOpensTableFilters, type EmailClientTagOption } from '../CampaignPerformanceClicksOpensTableFilters'
import { CampaignPerformanceClientList } from '../CampaignPerformanceClientList'
import { CampaignPerformanceTagChart } from '../CampaignPerformanceTagChart'
import { CampaignProgressLine } from '../CampaignProgressLine'
import { TopClicksPerformance } from './TopClicksPerformance'

export function CampaignPerformanceClicks({
  emailCampaign,
  activity,
}: {
  emailCampaign: OneTimeEmailCampaignType
  activity: CampaignActivityItem
}) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const { params } = useDestination(routes.manager2.marketing.oneTimeEmailCenter.viewDeepDive)
  const [filterQuery, setFilterQuery] = useState<string>('')
  const [nameEmailFilterString, setNameEmailFilterString] = useState<string>('')
  const [filterTags, setFilterTags] = useState<EmailClientTagOption[]>([])
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  })
  const clicksRate = activity.recipients > 0 ? Math.ceil((activity.clicks / activity.recipients) * 100) : 0
  const sentDate = emailCampaign.sentDateTimeLocal?.toJsDate()
  const daysCount = sentDate ? differenceInCalendarDays(new Date(), sentDate) : 0

  const { data: emailCampaignRecipientData, isFetching: isEmailCampaignRecipientDetailsLoading } = useGetEmailCampaignRecipientDetailsQuery(
    params.campaignId && venueId && paginationModel
      ? {
          venueId,
          id: params.campaignId,
          eventType: 'CLICKED',
          pageNumber: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
          nameEmailFilterString,
          tagFilterString: filterTags?.map(tag => tag.tag_id),
        }
      : skipToken
  )

  const { data: emailCampaignRecipientsByTagData, isFetching: isEmailCampaignRecipientsByTagLoading } =
    useGetEmailCampaignRecipientsByTagsQuery(
      params.campaignId && venueId
        ? {
            venueId,
            id: params.campaignId,
            eventType: 'CLICKED',
            nameEmailFilterString,
            tagFilterString: filterTags?.map(tag => tag.tag_id),
          }
        : skipToken
    )

  const { data: tagGroups, isLoading: isTagGroupsLoading } = useGetClientTagGroupsQuery({
    venueKey: venueId,
    includeRebuildState: false,
  })

  const recipients = emailCampaignRecipientData?.data?.clients ?? []

  const tags = useMemo(
    () =>
      _.flatten(
        (tagGroups ?? []).map(tagGroup =>
          _.map(_.isEmpty(tagGroup.tagNameDisplays) ? tagGroup.tags : tagGroup.tagNameDisplays, (tag, key) => ({
            group: tagGroup.nameDisplay,
            color_hex: tagGroup.colorHex,
            tag_name_display: tag as string,
            tag_id: `${tagGroup.privacy}##${tagGroup.id}##${tagGroup.name}##${_.isEmpty(tagGroup.tagNameDisplays) ? tag : key}`,
            is_autotag: tagGroup.isAutotag,
            tag_name: _.isEmpty(tagGroup.tagNameDisplays) ? tag : key,
          })).filter(tag => !_.includes(['All Guests', 'Group All Guests', 'Subscribed Guests', 'Group Marketing Opt-Ins'], tag.tag_name))
        )
      ),
    [tagGroups]
  )

  const linearProgressZoom = useMemo(() => {
    if (clicksRate < 5) {
      return 20
    } else if (clicksRate < 10) {
      return 10
    }

    return 1
  }, [clicksRate])

  const campaignPerformanceClicksTitle = useMemo(() => {
    if (clicksRate >= 2) {
      return formatMessage(campaignCenterMessages.campaignPerformanceClicksExcellentTitle)
    } else if (clicksRate >= 1) {
      return formatMessage(campaignCenterMessages.campaignPerformanceClicksGoodTitle)
    }

    return formatMessage(campaignCenterMessages.campaignPerformanceClicksFairTitle)
  }, [clicksRate, formatMessage])

  const campaignPerformanceClicksDescription = useMemo(() => {
    if (clicksRate >= 2) {
      return formatMessage(campaignCenterMessages.campaignPerformanceClicksExcellentDescription)
    } else if (clicksRate >= 1) {
      return formatMessage(campaignCenterMessages.campaignPerformanceClicksGoodDescription)
    }

    return formatMessage(campaignCenterMessages.campaignPerformanceClicksFairDescription, {
      link: (chunks: string) => (
        <Link
          data-test="fair-clicks-description-link"
          href="https://help.sevenrooms.com/hc/en-us/articles/6984167189403-Email-Marketing-101"
          target="_blank"
        >
          {chunks}
        </Link>
      ),
    })
  }, [clicksRate, formatMessage])

  useDebounceEffect(
    () => {
      setNameEmailFilterString(filterQuery)
    },
    500,
    [filterQuery]
  )

  return (
    <VStack mb="lm">
      <Grid gap="lm" pb="lm" gridTemplateColumns="minmax(0px, 1fr) auto">
        <VStack spacing="lm">
          <BaseSection title={campaignPerformanceClicksTitle} subCaption={campaignPerformanceClicksDescription}>
            <CampaignProgressLine performance={clicksRate} standard={1.5} zoom={linearProgressZoom} />
          </BaseSection>
          <BaseSection
            title={formatMessage(campaignCenterMessages.campaignPerformanceTop5ClicksTitle)}
            description={formatMessage(campaignCenterMessages.campaignPerformanceTop5ClicksDescription)}
          >
            <TopClicksPerformance />
          </BaseSection>
        </VStack>
        <VStack>
          <EmailCampaignPreview simpleView template={emailCampaign.emailBody} />
        </VStack>
      </Grid>

      <BaseSection
        maxWidth="none"
        title={formatMessage(campaignCenterMessages.campaignPerformanceClickByTagsTitle, {
          value: emailCampaignRecipientData?.data?.clientsTotal ?? 0,
        })}
        description={formatMessage(campaignCenterMessages.campaignPerformanceOpensByTagsDescription, { value: daysCount })}
        actions={
          <CampaignPerformanceClicksOpensTableFilters
            isTagGroupsLoading={isTagGroupsLoading}
            tagOptions={tags}
            filterTags={filterTags}
            onFilterTagsChange={setFilterTags}
            filterQuery={filterQuery}
            onFilterQueryChange={setFilterQuery}
          />
        }
      >
        <Stack px={6} mb={6} mt={6} flexDirection="column" gap={6}>
          {isEmailCampaignRecipientsByTagLoading ? (
            <Skeleton
              variant="rounded"
              sx={{
                height: 74,
                width: '100%',
              }}
            />
          ) : (
            emailCampaignRecipientsByTagData?.data?.tagStatistics &&
            !_.isEmpty(emailCampaignRecipientsByTagData?.data?.tagStatistics) && (
              <Box px="10%" maxHeight="520px" overflow="auto" width="100%" sx={{ backgroundColor: '#F7F7F7' }}>
                <CampaignPerformanceTagChart data={emailCampaignRecipientsByTagData?.data?.tagStatistics} />
              </Box>
            )
          )}

          <CampaignPerformanceClientList
            isLoading={isEmailCampaignRecipientDetailsLoading}
            rowCount={emailCampaignRecipientData?.data?.clientsTotal ?? 0}
            data={recipients}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </Stack>
      </BaseSection>
    </VStack>
  )
}
