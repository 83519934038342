import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetEmailCampaignActivityQuery, useGetEmailCampaignByIdQuery, type OneTimeEmailCampaignType } from '@sevenrooms/core/api'
import { MarketingCampaignTypeEnum, OneTimeEmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { useDestination } from '@sevenrooms/core/navigation'
import { format } from '@sevenrooms/core/timepiece'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { CssBaseline } from '@sevenrooms/react-components/components/CssBaseline'
import { routes } from '@sevenrooms/routes'
import { OneTimeEmailCampaignDeepDiveViewerForm } from '../../components'

export function OneTimeEmailCampaignDeepDiveViewer() {
  const { venueId } = useVenueContext()
  const { params } = useDestination(routes.manager2.marketing.oneTimeEmailCenter.viewDeepDive)
  const { campaignId } = params

  const { data: campaignData, isFetching: isCampaignFetching } = useGetEmailCampaignByIdQuery(
    campaignId && venueId ? { campaignId, venueId } : skipToken
  )
  const { data: campaignActivityData, isFetching: isActivityFetching } = useGetEmailCampaignActivityQuery(
    campaignId && venueId
      ? {
          venueId,
          campaignIds: [campaignId],
          endDate: format(new Date(), 'yyyy-MM-dd'),
          startDate: format(new Date(2000, 1), 'yyyy-MM-dd'),
          campaignType: MarketingCampaignTypeEnum.ONE_TIME,
          campaignStatus: [OneTimeEmailCampaignStatusEnum.SENT],
        }
      : skipToken
  )

  return (
    <CssBaseline>
      {isCampaignFetching || isActivityFetching || !campaignActivityData || !campaignData ? (
        <Loader />
      ) : (
        <OneTimeEmailCampaignDeepDiveViewerForm
          campaignActivityData={campaignActivityData}
          emailCampaign={campaignData.emailCampaign as OneTimeEmailCampaignType}
        />
      )}
    </CssBaseline>
  )
}
