import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  stepOfferDetails: {
    id: 'experienceStepperFlow.stepOfferDetails',
    defaultMessage: 'Offer Details',
  },
  stepAvailability: {
    id: 'experienceStepperFlow.stepAvailability',
    defaultMessage: 'Availability',
  },
  stepScheduling: {
    id: 'experienceStepperFlow.stepScheduling',
    defaultMessage: 'Scheduling',
  },
  stepPayment: {
    id: 'experienceStepperFlow.stepPayment',
    defaultMessage: 'Payment',
  },
  stepCustomization: {
    id: 'experienceStepperFlow.stepCustomization',
    defaultMessage: 'Customization',
  },
  offerDetailsStepTitle: {
    id: 'experienceStepperFlow.offerDetailsStepTitle',
    defaultMessage: "Let's get started! First we'll need a few guest-facing details.",
  },
  offerDetailsStepDescription: {
    id: 'experienceStepperFlow.offerDetailsStepDescription',
    defaultMessage: 'Offer details have been prefilled by the template',
  },
  offerTitle: {
    id: 'experienceStepperFlow.offerTitle',
    defaultMessage: 'What do you want to call your Offer?',
  },
  offerTitleRecommendation: {
    id: 'experienceStepperFlow.offerTitleRecommendation',
    defaultMessage: 'Names with common phrases perform best since they are easily recognizable and searchable by guests.',
  },
  offerDescription: {
    id: 'experienceStepperFlow.offerDescription',
    defaultMessage: 'How do you want to describe this Offer to guests?',
  },
  offerPreviewDescription: {
    id: 'experienceStepperFlow.offerPreviewDescription',
    defaultMessage: 'What preview description do you want on your widget? (1-2 words)',
  },
  offerPreviewDescriptionPlaceholder: {
    id: 'experienceStepperFlow.offerPreviewDescriptionPlaceholder',
    defaultMessage: 'Enter a timeslot description',
  },
  offerPreviewDescriptionRecommendation: {
    id: 'experienceStepperFlow.offerPreviewDescriptionRecommendation',
    defaultMessage: 'Brief and informative descriptions will attract more bookings.',
  },
  offerPrice: {
    id: 'experienceStepperFlow.offerPrice',
    defaultMessage: 'What price is your Offer?',
  },
  offerPricePlaceholder: {
    id: 'experienceStepperFlow.offerPricePlaceholder',
    defaultMessage: 'Enter pricing information',
  },
  offerImage: {
    id: 'experienceStepperFlow.offerImage',
    defaultMessage: 'What image would you like to use for this Offer?',
  },
  availabilityStepTitle: {
    id: 'experienceStepperFlow.availabilityStepTitle',
    defaultMessage: "Next let's decide where you want {offerName} to be made available.",
  },
  availabilityStepDescription: {
    id: 'experienceStepperFlow.availabilityStepDescription',
    defaultMessage: 'To make {offerName} available to book on your Reservation or Events widget, it must be linked to an Access Rule.',
  },
  createNewAccessRuleButtonText: {
    id: 'experienceStepperFlow.createNewAccessRuleButtonText',
    defaultMessage: 'Create New Access Rule',
  },
  linkToExistingAccessRuleButtonText: {
    id: 'experienceStepperFlow.linkToExistingAccessRuleButtonText',
    defaultMessage: 'Link to Existing Access Rule(s)',
  },
  linkToExistingAccessRuleStepTitle: {
    id: 'experienceStepperFlow.linkToExistingAccessRuleStepTitle',
    defaultMessage: 'Link {offerName} to existing Access Rule(s)',
  },
  linkToExistingAccessRuleRecommendation: {
    id: 'experienceStepperFlow.linkToExistingAccessRuleRecommendation',
    defaultMessage: 'You can select multiple access rules to link.',
  },
  selectAccessRulesToLinkTheOfferTo: {
    id: 'experienceStepperFlow.selectAccessRulesToLinkTheOfferTo',
    defaultMessage: 'Select Access Rule(s) to link your Offer to',
  },
  newAccessRuleSchedulingStepTitle: {
    id: 'experienceStepperFlow.newAccessRuleSchedulingStepTitle',
    defaultMessage: 'When do you want {offerName} to be available?',
  },
  newAccessRuleSchedulingHelpfulTip: {
    id: 'experienceStepperFlow.newAccessRuleSchedulingHelpfulTip',
    defaultMessage: 'Only select an end date if you want your Offer to be available for a limited length of time.',
  },
  newAccessRuleSchedulingDate: {
    id: 'experienceStepperFlow.newAccessRuleSchedulingDate',
    defaultMessage: 'Should this Offer be available for a limited length of time?',
  },
  newAccessRuleSchedulingDayOfTheWeek: {
    id: 'experienceStepperFlow.newAccessRuleSchedulingDayOfTheWeek',
    defaultMessage: 'What days of the week should this Offer be available?',
  },
  newAccessRuleSchedulingShifts: {
    id: 'experienceStepperFlow.newAccessRuleSchedulingShifts',
    defaultMessage: 'Which Shifts should this Access Rule apply to?',
  },
  newAccessRuleSchedulingStartDate: {
    id: 'experienceStepperFlow.newAccessRuleSchedulingStartDate',
    defaultMessage: 'Start date',
  },
  newAccessRuleSchedulingEndDate: {
    id: 'experienceStepperFlow.newAccessRuleSchedulingEndDate',
    defaultMessage: 'End date',
  },
  newAccessRulePaymentStepTitle: {
    id: 'experienceStepperFlow.newAccessRulePaymentStepTitle',
    defaultMessage: "You're almost done. But first we need a few payment details for {offerName}",
  },
  newAccessRulePaymentBestPractice: {
    id: 'experienceStepperFlow.newAccessRulePaymentBestPractice',
    defaultMessage: 'Capture payment in advance to lock in revenue and help reduce no-shows and cancellations.',
  },
  newAccessRulePaymentMethod: {
    id: 'experienceStepperFlow.newAccessRulePaymentMethod',
    defaultMessage: 'How do you want to capture payment?',
  },
  newAccessRulePaymentDetails: {
    id: 'experienceStepperFlow.newAccessRulePaymentDetails',
    defaultMessage: 'Who should be charged and how much?',
  },
  newAccessRuleCustomizationStepTitle: {
    id: 'experienceStepperFlow.newAccessRuleCustomizationStepTitle',
    defaultMessage: 'Do you need to further customize the availability for {offerName}?',
  },
  newAccessRuleCustomizationOfferType: {
    id: 'experienceStepperFlow.newAccessRuleCustomizationOfferType',
    defaultMessage: 'This Offer is',
  },
  newAccessRuleCustomizationOfferTypePublic: {
    id: 'experienceStepperFlow.newAccessRuleCustomizationOfferTypePublic',
    defaultMessage: 'Public',
  },
  newAccessRuleCustomizationOfferTypeExclusive: {
    id: 'experienceStepperFlow.newAccessRuleCustomizationOfferTypeExclusive',
    defaultMessage: 'Exclusive',
  },
  newAccessRuleCustomizationPartySizeExpanded: {
    id: 'experienceStepperFlow.newAccessRuleCustomizationPartySizeExpanded',
    defaultMessage: 'Which party sizes should be able to book this Offer?',
  },
  newAccessRuleCustomizationPartySizeMin: {
    id: 'experienceStepperFlow.newAccessRuleCustomizationPartySizeMin',
    defaultMessage: 'Min party size',
  },
  newAccessRuleCustomizationPartySizeMax: {
    id: 'experienceStepperFlow.newAccessRuleCustomizationPartySizeMax',
    defaultMessage: 'Max party size',
  },
  newAccessRuleCustomizationSeatingAreas: {
    id: 'experienceStepperFlow.newAccessRuleCustomizationSeatingAreas',
    defaultMessage: 'Which seating areas should this Offer be available to book in?',
  },
  newAccessRuleReviewStepTitle: {
    id: 'experienceStepperFlow.newAccessRuleReviewStepTitle',
    defaultMessage: "Let's review your Access Rule for {offerName}",
  },
  newAccessRuleReviewCustomizeBtn: {
    id: 'experienceStepperFlow.newAccessRuleReviewCustomizeBtn',
    defaultMessage: 'Customize Access Rule',
  },
  newAccessRuleReviewOfferDetails: {
    id: 'experienceStepperFlow.newAccessRuleReviewOfferDetails',
    defaultMessage: 'Offer Details',
  },
  newAccessRuleReviewAccessRule: {
    id: 'experienceStepperFlow.newAccessRuleReviewAccessRule',
    defaultMessage: 'Access Rule - {accessRule}',
  },
  newAccessRuleReviewOfferName: {
    id: 'experienceStepperFlow.newAccessRuleReviewOfferName',
    defaultMessage: 'Name',
  },
  newAccessRuleReviewOfferDescription: {
    id: 'experienceStepperFlow.newAccessRuleReviewOfferDescription',
    defaultMessage: 'Description',
  },
  newAccessRuleReviewOfferPrice: {
    id: 'experienceStepperFlow.newAccessRuleReviewOfferPrice',
    defaultMessage: 'Price',
  },
  newAccessRuleReviewOfferType: {
    id: 'experienceStepperFlow.newAccessRuleReviewOfferType',
    defaultMessage: 'Type',
  },
  newAccessRuleReviewOfferImage: {
    id: 'experienceStepperFlow.newAccessRuleReviewOfferImage',
    defaultMessage: 'Image',
  },
  newAccessRuleReviewSchedule: {
    id: 'experienceStepperFlow.newAccessRuleReviewSchedule',
    defaultMessage: 'Schedule',
  },
  newAccessRuleReviewDays: {
    id: 'experienceStepperFlow.newAccessRuleReviewDays',
    defaultMessage: 'Days',
  },
  newAccessRuleReviewDateRange: {
    id: 'experienceStepperFlow.newAccessRuleReviewDateRange',
    defaultMessage: 'Date Range',
  },
  newAccessRuleReviewEndDateIndefinite: {
    id: 'experienceStepperFlow.newAccessRuleReviewEndDateIndefinite',
    defaultMessage: 'indefinite',
  },
  newAccessRuleReviewTimes: {
    id: 'experienceStepperFlow.newAccessRuleReviewTimes',
    defaultMessage: 'Time(s)',
  },
  newAccessRuleReviewShifts: {
    id: 'experienceStepperFlow.newAccessRuleReviewShifts',
    defaultMessage: 'Shift Categories',
  },
  newAccessRuleReviewPartySize: {
    id: 'experienceStepperFlow.newAccessRuleReviewPartySize',
    defaultMessage: 'Party Size',
  },
  accessRule: {
    id: 'experienceStepperFlow.accessRule',
    defaultMessage: 'Access Rule',
  },
  finalPageTitle: {
    id: 'experienceStepperFlow.finalPageTitle',
    defaultMessage: 'Congratulations! Your new {offerName} offer is live.',
  },
  finalPageDescription: {
    id: 'experienceStepperFlow.finalPageDescription',
    defaultMessage: 'Use this link to include on your website, send out an email blast or post to social media.',
  },
  viewOfferLandingPageButtonText: {
    id: 'experienceStepperFlow.viewOfferLandingPageButtonText',
    defaultMessage: 'View Offer Landing Page',
  },
  viewOffersButtonText: {
    id: 'experienceStepperFlow.viewOffersButtonText',
    defaultMessage: 'View Offers',
  },
  experienceAPIError: {
    id: 'experienceStepperFlow.experienceAPIError',
    defaultMessage: 'Something went wrong, please try again or contact support team.',
  },
  recommendationTipBestPractice: {
    id: 'experienceStepperFlow.recommendationTipBestPractice',
    defaultMessage: 'Best practice',
  },
  recommendationTipHelpfulTip: {
    id: 'experienceStepperFlow.recommendationTipHelpfulTip',
    defaultMessage: 'Helpful tip',
  },
  editButton: {
    id: 'experienceStepperFlow.editButton',
    defaultMessage: 'Edit',
  },
  nextButtonText: {
    id: 'experienceStepperFlow.nextButtonText',
    defaultMessage: 'Next',
  },
  backButtonText: {
    id: 'experienceStepperFlow.backButtonText',
    defaultMessage: 'Back',
  },
  finishButtonText: {
    id: 'experienceStepperFlow.finishButtonText',
    defaultMessage: 'Finish',
  },
  cancelButtonText: {
    id: 'experienceStepperFlow.cancelButtonText',
    defaultMessage: 'Cancel',
  },
  saveButtonText: {
    id: 'experienceStepperFlow.saveButtonText',
    defaultMessage: 'Save',
  },
  fieldLengthError: {
    id: 'experienceStepperFlow.fieldLengthError',
    defaultMessage: 'Should be at least {minSize} character',
  },
  fieldValueMinError: {
    id: 'experienceStepperFlow.fieldValueMinError',
    defaultMessage: 'Should be at least {min}',
  },
  daysOfTheWeekRequired: {
    id: 'experienceStepperFlow.daysOfTheWeekRequired',
    defaultMessage: 'Days of the week selection required',
  },
  followShiftSettings: {
    id: 'experienceStepperFlow.followShiftSettings',
    defaultMessage: 'Follow Shift Settings',
  },
})
