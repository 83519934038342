import { useMemo, useState } from 'react'
import type { CampaignActivityItem } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Select } from '@sevenrooms/core/ui-kit/core'
import { Label } from '@sevenrooms/core/ui-kit/form'
import { Grid, BaseSection, BorderedBox, VStack } from '@sevenrooms/core/ui-kit/layout'
import { campaignCenterMessages } from '@sevenrooms/marketing'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { ReservationsTable } from './ReservationsTable'

const DAYS_OPTIONS = [1, 7, 14, 30]

export function CampaignPerformanceReservations({ activity }: { activity: CampaignActivityItem }) {
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const { formatMessage } = useLocales()
  const [selectedDay, setSelectedDay] = useState('30')

  const options = useMemo(
    () => [
      { id: 'directClick', label: formatMessage(campaignCenterMessages.attributionWindowDirectClick) },
      ...DAYS_OPTIONS.map(day => ({ id: `${day}`, label: formatMessage(campaignCenterMessages.attributionWindowDays, { count: day }) })),
    ],
    [formatMessage]
  )

  const isPOSActivated = venueSettings?.pos_activated
  return (
    <VStack spacing="lm">
      <Label primary={formatMessage(campaignCenterMessages.attributionWindow)} width={440}>
        <Select data-test="attribution-window-select" options={options} value={selectedDay} searchable={false} onChange={setSelectedDay} />
      </Label>
      <Grid gridTemplateColumns={`1fr ${isPOSActivated ? 2 : 1}fr`} gap="lm">
        <BaseSection
          title={`${venue.currencySymbol} ${Math.round(activity.totalRevenue)}`}
          subCaption={formatMessage(campaignCenterMessages.campaignPerformanceRevenueGenerated)}
          padding="m"
          collapsed
        />
        <Grid gridTemplateColumns={`1fr ${isPOSActivated ? '1fr' : ''}`} gap="lm">
          {isPOSActivated && (
            <BaseSection
              title={formatMessage(commonMessages.hours, { count: 2 })}
              subCaption={formatMessage(campaignCenterMessages.campaignPerformanceTimeBetween)}
              padding="m"
              collapsed
            />
          )}
          <BaseSection
            title={formatMessage(commonMessages.daysInAdvance, { count: 5 })}
            subCaption={formatMessage(campaignCenterMessages.campaignPerformanceBookingLeadTime)}
            padding="m"
            collapsed
          />
        </Grid>
      </Grid>
      <Grid gridTemplateColumns="1fr 2fr" gap="lm">
        <BaseSection
          maxWidth="none"
          title={formatMessage(campaignCenterMessages.campaignPerformanceTop3CohortsTitle)}
          description={formatMessage(campaignCenterMessages.campaignPerformanceTop3CohortsDescription)}
        >
          <BorderedBox backgroundColor="margin" height="361px" m="lm" />
        </BaseSection>
        <BaseSection
          maxWidth="none"
          title={formatMessage(campaignCenterMessages.campaignPerformanceBookingsTitle)}
          description={formatMessage(campaignCenterMessages.campaignPerformanceBookingsDescription)}
        >
          <BorderedBox backgroundColor="margin" height="361px" m="lm" />
        </BaseSection>
      </Grid>
      <ReservationsTable isPOSActivated={isPOSActivated} reservationsCount={activity.reservations} />
    </VStack>
  )
}
