import { useMemo } from 'react'
import {
  type PDRTimeSlots,
  ExperienceStatusEnum,
  type Experience,
  type ExperienceStatus,
  type PDRAmenitiesEnumOption,
  type PDRRoomTypeEnumOption,
  type PDRUnitOfMeasurementEnumOption,
  type HeroImage,
  type PDRDeadlineTimeFromEnumOption,
  type PDRDeadlineTimeUnitEnumOption,
  type PDRCancellationDeadlineFromOption,
} from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { formSchema, useImageForm } from '@sevenrooms/core/ui-kit/form'
import { spacesMessages } from '../../spaces.locales'
import type { DepositCalcFromOption } from './selectOptions'

export const useSpaceForm = () => {
  const { formatMessage } = useLocales()
  const image = useImageForm()
  const minimumSpendMatrix = useMinimumSpendMatrixForm()
  const cancellationDeadline = useCancellationDeadlineMatrixForm()
  return useMemo(
    () =>
      z
        .object({
          status: z.custom<ExperienceStatus>(),
          name: z.string().trim().min(1),
          description: z.string().trim().min(1),
          heroImage: image.nullable(),
          imageList: z.array(image.nullable()),
          pdrMaxSeatedCapacity: z.number().nullable(),
          pdrMinSeatedCapacity: z.number().nullable(),
          pdrMaxStandingCapacity: z.number().nullable(),
          pdrMinStandingCapacity: z.number().nullable(),
          pdrRoomType: z.custom<PDRRoomTypeEnumOption>().nullable(),
          pdrSquareFootage: z.number().nullable(),
          pdrUnitOfMeasurement: z.custom<PDRUnitOfMeasurementEnumOption>().nullable(),
          depositCalcFrom: z.custom<DepositCalcFromOption>().nullable(),
          pdrAmenityList: z.custom<PDRAmenitiesEnumOption[]>(),
          menu: formSchema,
          eventType: z.array(z.string()),
          pdrFees: z.array(z.string()),
          pdrAdminFee: z.number().nullable(),
          pdrTaxRateId: z.string().nullable(),
          pdrMinSpendMin: z.number().nullable(),
          pdrMinSpendMax: z.number().nullable(),
          pdrMinSpend: z.number().nullable(),
          pdrMinimumSpendMatrix: z.array(minimumSpendMatrix),
          pdrTripleSeatRoomId: z.string().nullable(),
          pdrDepositFee: z.number().nullable(),
          requirePreApproval: z.boolean().optional(),
          requireContract: z.boolean().optional(),
          unlinkedAccessRuleIDs: z.array(z.string()),
          linkedAccessRuleIDs: z.array(z.string()),
          pdrDeadlineNum: z.number().nullable(),
          pdrDeadlineTimeUnit: z.custom<PDRDeadlineTimeUnitEnumOption>().nullable(),
          pdrDeadlineTimeFrom: z.custom<PDRDeadlineTimeFromEnumOption>().nullable(),
          pdrCancellationPolicy: z.string().nullable().optional(),
          pdrCustomCancellationPolicy: z.string().nullable().optional(),
          cancellationDeadlines: z.array(cancellationDeadline),
        })
        .refine(data => !data.requireContract || data.pdrCancellationPolicy, {
          message: formatMessage(spacesMessages.pdrCancellationPolicyRequired),
          path: ['pdrCancellationPolicy'],
        })
        .refine(data => data.pdrCancellationPolicy !== 'custom' || data.pdrCustomCancellationPolicy, {
          message: formatMessage(spacesMessages.pdrCustomCancellationPolicyRequired),
          path: ['pdrCustomCancellationPolicy'],
        })
        .superRefine((data, ctx) => {
          validateTimeSlotConflicts(data.pdrMinimumSpendMatrix, ctx)
        }),
    [image, minimumSpendMatrix, formatMessage, cancellationDeadline]
  )
}

export type SpaceFormType = ZodSchema<typeof useSpaceForm>

const useMinimumSpendMatrixForm = () =>
  useMemo(
    () =>
      z.object({
        startDay: z.string(),
        endDay: z.string(),
        startTime: z.custom<PDRTimeSlots>(),
        endTime: z.custom<PDRTimeSlots>(),
        minimumSpend: z.number(),
      }),
    []
  )

interface TimeSlot {
  startDay: string
  startTime: string
  endDay: string
  endTime: string
}

function secondsSinceDayStarted(time: string): number {
  const [hours, minutes, seconds] = time.split(':').map(Number) as [number, number, number]
  if (Number.isNaN(hours) || Number.isNaN(minutes) || Number.isNaN(seconds)) {
    throw new Error(`Invalid time: ${time}`)
  }

  return hours * 3600 + minutes * 60 + seconds
}
function doRangesOverlap([aStart, aEnd]: [number, number], [bStart, bEnd]: [number, number], limit: number): boolean {
  if (aStart > aEnd) {
    const AsubRangeBeforeLimitOverlap = doRangesOverlap([aStart, limit], [bStart, bEnd], limit)
    const AsubRangeAfterLimitOverlap = doRangesOverlap([0, aEnd], [bStart, bEnd], limit)
    return AsubRangeBeforeLimitOverlap || AsubRangeAfterLimitOverlap
  }
  if (bStart > bEnd) {
    const BsubRangeBeforeLimitOverlap = doRangesOverlap([aStart, aEnd], [bStart, limit], limit)
    const BsubRangeAfterLimitOverlap = doRangesOverlap([aStart, aEnd], [0, bEnd], limit)
    return BsubRangeBeforeLimitOverlap || BsubRangeAfterLimitOverlap
  }
  const [aMin, aMax] = [Math.min(aStart, aEnd), Math.max(aStart, aEnd)]
  const [bMin, bMax] = [Math.min(bStart, bEnd), Math.max(bStart, bEnd)]

  return aMin <= bMax && bMin <= aMax
}

function slotsOverlap(slotA: TimeSlot, slotB: TimeSlot): boolean {
  const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']

  const startDayA = days.indexOf(slotA.startDay)
  const endDayA = days.indexOf(slotA.endDay)
  const startDayB = days.indexOf(slotB.startDay)
  const endDayB = days.indexOf(slotB.endDay)

  const startSecondsA = secondsSinceDayStarted(slotA.startTime)
  const endSecondsA = secondsSinceDayStarted(slotA.endTime)
  const startSecondsB = secondsSinceDayStarted(slotB.startTime)
  const endSecondsB = secondsSinceDayStarted(slotB.endTime)

  const timeOverlaps = doRangesOverlap([startSecondsA, endSecondsA], [startSecondsB, endSecondsB], 86400) // Last second of the day
  if (timeOverlaps) {
    return doRangesOverlap([startDayA, endDayA], [startDayB, endDayB], 6) // Last day of the week
  }
  return false
}

function validateTimeSlotConflicts(timeSlots: TimeSlot[], ctx: { addIssue: Function }): void {
  for (let i = 0; i < timeSlots?.length; i += 1) {
    const slotA = timeSlots[i]
    if (!slotA) {
      continue
    }

    for (let j = i + 1; j < timeSlots?.length; j += 1) {
      const slotB = timeSlots[j]
      if (!slotB) {
        continue
      }

      if (slotsOverlap(slotA, slotB)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Conflict with timeslot ${j + 1}`,
          path: ['pdrMinimumSpendMatrix', i, 'minimumSpend'],
        })
        return
      }
    }
  }
}

export function getDefaultValues(experience?: Experience) {
  if (experience) {
    return {
      status: experience.status,
      name: experience.name,
      description: experience.description,
      heroImage: experience.heroImage?.rawUrlKey ? getHeroImageForm(experience.heroImage) : null,
      imageList: experience.imageList?.map(getHeroImageForm) ?? [],
      pdrMaxSeatedCapacity: experience.pdrMaxSeatedCapacity,
      pdrMinSeatedCapacity: experience.pdrMinSeatedCapacity,
      pdrMaxStandingCapacity: experience.pdrStandingCapacity,
      pdrMinStandingCapacity: experience.pdrStandingMinCapacity ?? null,
      pdrRoomType: experience.pdrRoomType,
      pdrSquareFootage: experience.pdrSquareFootage,
      pdrUnitOfMeasurement: experience.pdrUnitOfMeasurement,
      pdrAmenityList: experience.pdrAmenityList,
      menu: experience.menuBlobId ? { rawUrl: experience.menuBlobId, name: experience.menuFilename } : { rawUrl: '', name: '' },
      eventType: experience.eventType,
      pdrFees: experience.pdrFees,
      pdrAdminFee: experience.pdrAdminFee,
      pdrTaxRateId: experience.pdrTaxRateId,
      pdrMinSpendMin: experience.pdrMinSpendMin,
      pdrMinSpendMax: experience.pdrMinSpendMax,
      pdrMinSpend: experience.pdrMinSpend,
      pdrMinimumSpendMatrix: experience.pdrMinimumSpendMatrix,
      pdrTripleSeatRoomId: experience.pdrTripleSeatRoomId,
      pdrDepositFee: experience.pdrDepositFee,
      requirePreApproval: experience.requirePreApproval ?? false,
      depositCalcFrom: experience.depositCalcFrom,
      requireContract: experience.requireContract ?? false,
      unlinkedAccessRuleIDs: [],
      linkedAccessRuleIDs: [],
      pdrDeadlineNum: experience.pdrDeadlineNum,
      pdrDeadlineTimeUnit: experience.pdrDeadlineTimeUnit,
      pdrDeadlineTimeFrom: experience.pdrDeadlineTimeFrom,
      pdrCancellationPolicy: experience.pdrCancellationPolicy,
      pdrCustomCancellationPolicy: experience.pdrCustomCancellationPolicy,
      cancellationDeadlines: experience.cancellationDeadlines,
    } as SpaceFormType
  }
  return {
    status: ExperienceStatusEnum.DRAFT,
    name: '',
    description: '',
    heroImage: null,
    imageList: [],
    pdrMaxSeatedCapacity: null,
    pdrMinSeatedCapacity: null,
    pdrMaxStandingCapacity: null,
    pdrMinStandingCapacity: null,
    pdrRoomType: null,
    pdrSquareFootage: null,
    pdrUnitOfMeasurement: null,
    pdrAmenityList: [],
    menu: null,
    pdrFees: [],
    pdrAdminFee: null,
    pdrTaxRateId: null,
    pdrMinSpendMin: null,
    pdrMinSpendMax: null,
    pdrMinSpend: null,
    pdrMinimumSpendMatrix: [],
    pdrTripleSeatRoomId: null,
    pdrDepositFee: null,
    unlinkedAccessRuleIDs: [],
    linkedAccessRuleIDs: [],
    pdrDeadlineNum: null,
    pdrDeadlineTimeUnit: null,
    pdrDeadlineTimeFrom: null,
    eventType: [],
    pdrCancellationPolicy: null,
    pdrCustomCancellationPolicy: null,
    cancellationDeadlines: [],
  }
}

function getHeroImageForm(image: HeroImage) {
  return {
    name: ' ',
    rawUrl: `/.h/download/${image.rawUrlKey}`,
    crop: image.cropInfo,
  }
}

export type CancellationDeadlineType = ZodSchema<typeof useCancellationDeadlineMatrixForm>
export const useCancellationDeadlineMatrixForm = () =>
  useMemo(
    () =>
      z.object({
        number: z.number().int().min(0).default(1),
        timeUnit: z.custom<PDRDeadlineTimeUnitEnumOption>().default('DAY'),
        refund: z.number().min(0).max(100).default(100),
        refundFrom: z.custom<PDRCancellationDeadlineFromOption>().default('DEPOSIT'),
      }),
    []
  )
