/* eslint-disable camelcase */
import { CheckBox, CheckBoxOutlineBlank, Search } from '@mui/icons-material'
import _ from 'lodash'
import { useLocales } from '@sevenrooms/core/locales'
import { campaignCenterMessages, Tag } from '@sevenrooms/marketing'
import { Autocomplete, Checkbox, InputAdornment, Skeleton, Stack, TextField } from '@sevenrooms/react-components'

export interface CampaignPerformanceClicksOpensTableFiltersProps {
  isTagGroupsLoading: boolean
  tagOptions: EmailClientTagOption[]
  filterTags: EmailClientTagOption[]
  onFilterTagsChange: (value: EmailClientTagOption[]) => void
  filterQuery: string
  onFilterQueryChange: (value: string) => void
}

export interface EmailClientTagOption {
  group: string
  color_hex: string
  tag_name_display: string
  tag_id: string
  is_autotag: boolean
}

export function CampaignPerformanceClicksOpensTableFilters({
  isTagGroupsLoading,
  tagOptions,
  filterTags,
  onFilterTagsChange,
  filterQuery,
  onFilterQueryChange,
}: CampaignPerformanceClicksOpensTableFiltersProps) {
  const { formatMessage } = useLocales()
  const icon = <CheckBoxOutlineBlank fontSize="small" />
  const checkedIcon = <CheckBox fontSize="small" />

  return (
    <Stack gap="16px" flexDirection="row">
      {isTagGroupsLoading ? (
        <Skeleton
          variant="rounded"
          sx={{
            height: 42,
            width: 300,
            marginTop: '4px',
          }}
        />
      ) : (
        <Autocomplete
          value={filterTags}
          onChange={(_event, newValue) => {
            onFilterTagsChange(newValue)
          }}
          multiple
          id="filter-tags"
          options={tagOptions}
          groupBy={option => option.group}
          disableCloseOnSelect
          getOptionLabel={option => option.tag_name_display}
          getOptionKey={option => option.tag_id}
          sx={{
            width: 300,
          }}
          limitTags={1}
          renderOption={(props, option, { selected }) => {
            const { key, ...optionProps } = props
            const isLoyalty = _.startsWith(option.tag_id, 'LOYALTY')

            return (
              <li key={key} {...optionProps}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{ marginRight: 8 }} checked={selected} />
                <Tag
                  key={option.tag_id}
                  isLoyalty={isLoyalty}
                  isAuto={option.is_autotag}
                  color={option.color_hex}
                  title={option.tag_name_display}
                />
              </li>
            )
          }}
          renderInput={params => (
            <div>
              <TextField placeholder={formatMessage(campaignCenterMessages.searchTags)} {...params} />
            </div>
          )}
        />
      )}

      <TextField
        value={filterQuery}
        onChange={event => {
          onFilterQueryChange(event.target.value)
        }}
        sx={{ width: 300 }}
        placeholder={formatMessage(campaignCenterMessages.searchNameOrEmail)}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          },
        }}
      />
    </Stack>
  )
}
