import { Fragment } from 'react'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface CardItem {
  title: string
  value: string | number
  withPercent?: boolean
}

export function Card({ heading, items, isEmpty }: { heading: string; items: CardItem[]; isEmpty: boolean }) {
  return (
    <VStack>
      <Box backgroundColor={isEmpty ? 'secondaryBackground' : 'margin'} textAlign="center" p="xs">
        <Text color={isEmpty ? 'borderHover' : 'primaryIcons'} fontWeight={400}>
          {heading.toUpperCase()}
        </Text>
      </Box>
      <HStack alignItems="center" justifyContent="space-around" p="lm">
        {items.map(({ value, title, withPercent }, index: number) => (
          <Fragment key={title}>
            {index > 0 && <Box width="1px" height="30px" backgroundColor="margin" />}
            <VStack alignItems="center" spacing="s" width="120px">
              {isEmpty ? (
                <HStack alignItems="center">
                  <Box backgroundColor="secondaryBackground" borderRadius="m" width="50px" height="25px" />
                  {withPercent && (
                    <Text color="borderHover" fontSize="xl" fontWeight={600}>
                      %
                    </Text>
                  )}
                </HStack>
              ) : (
                <Text fontSize="xl" fontWeight={600} color="primaryFont">
                  {value}
                </Text>
              )}
              <Text fontSize="s" color={isEmpty ? 'borderHover' : 'primaryFont'}>
                {title}
              </Text>
            </VStack>
          </Fragment>
        ))}
      </HStack>
    </VStack>
  )
}
