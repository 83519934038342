// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { campaignCenterMessages } from '@sevenrooms/marketing'

export function CampaignProgressLine({ performance, standard, zoom = 1 }: { performance: number; standard: number; zoom?: number }) {
  const { formatMessage } = useLocales()

  return (
    <Box p="lm" minWidth="363px">
      <Box width="100%" display="flex" mb="m">
        <Campaign progress={performance} zoom={zoom}>
          {formatMessage(campaignCenterMessages.campaignPerformanceOpenRateBenchmark, { performance })}
        </Campaign>
      </Box>

      <ProgressLine>
        <CampaignArrow progress={performance} zoom={zoom} />
        <IndustryStandardArrow progress={standard} zoom={zoom} />
      </ProgressLine>

      <Box width="100%" display="flex" mt="m">
        <IndustryStandard progress={standard} zoom={zoom}>
          {formatMessage(campaignCenterMessages.campaignPerformanceOpenRateStandard, { standard })}
        </IndustryStandard>
      </Box>
    </Box>
  )
}

const ProgressLine = styled.div`
  position: relative;
  width: 100%;
  height: 9px;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(124, 177, 223, 1) 0%, rgba(124, 123, 193, 1) 100%);
`

const IndustryStandard = styled.div<{ progress: number; zoom: number }>`
  display: flex;
  margin-left: auto;
  flex-basis: calc(${props => 100 - props.progress * props.zoom}% + 75px);
  color: ${props => props.theme.colors.inactive};
`

const Campaign = styled.div<{ progress: number; zoom: number }>`
  display: flex;
  margin-left: auto;
  flex-basis: calc(${props => 100 - props.progress * props.zoom}% + 40px);
  color: ${props => props.theme.colors.darkFont};
  font-weight: bold;
  min-width: 112px;
`

const CampaignArrow = styled.div<{ progress: number; zoom: number }>`
  left: ${props => (props.progress * props.zoom * 100) / 100}%;
  width: 3px;
  height: 14px;
  background: currentColor;
  position: absolute;
  display: flex;
  justify-content: center;
  border-radius: 7px;
  bottom: 4px;
  margin-left: 2px;
  margin-right: 2px;

  &:before {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: currentColor;
    top: 10px;
  }
`

const IndustryStandardArrow = styled.div<{ progress: number; zoom: number }>`
  color: ${props => props.theme.colors.inactive};
  left: ${props => (props.progress * 100 * props.zoom) / 100}%;
  width: 3px;
  height: 14px;
  background: currentColor;
  position: absolute;
  display: flex;
  justify-content: center;
  border-radius: 7px;
  top: 3px;
  margin-left: 2px;
  margin-right: 2px;

  &:before {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: currentColor;
    top: -2px;
  }
`
