/* eslint-disable-next-line no-restricted-imports */
import { linearProgressClasses } from '@mui/material/LinearProgress'
import _ from 'lodash'
import { Tag } from '@sevenrooms/marketing'
import { Box, LinearProgress, Table, TableBody, TableCell, TableRow, Typography } from '@sevenrooms/react-components'
import type { EmailCampaignEventByTag } from '../../api'

interface CampaignPerformanceTagChartProps {
  data: EmailCampaignEventByTag[]
}

export function CampaignPerformanceTagChart({ data }: CampaignPerformanceTagChartProps) {
  const filteredTags = _.filter(data, tag => {
    const tagName = _.last(tag?.tag?.tagHash?.split('##'))
    return !_.includes(['All Guests', 'Group All Guests', 'Subscribed Guests', 'Group Marketing Opt-Ins'], tagName)
  })
  const result = _.orderBy(filteredTags, row => row.count, 'desc')
  const counts = data.map(row => row.count)
  const maxCount = Math.max(...counts)
  const minCount = Math.min(...counts)

  return (
    <Table width="100%" size="small" sx={{ marginY: 4 }}>
      <TableBody>
        {result.map(row => {
          const { tag, count } = row
          const isLoyalty = _.startsWith(tag?.tagGroupName, 'LOYALTY')
          const width = (100 * (count - minCount)) / (maxCount - minCount)

          return (
            <TableRow
              key={row?.tag?.tagHash}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'th,td': { borderTop: 0, borderLeft: 0, borderRight: 0 } }}
            >
              <TableCell width="100px" align="right" component="th" scope="row">
                <Tag isLoyalty={isLoyalty} isAuto={tag?.isAutotag} color={tag.tagColor} title={tag.tagNameDisplay} />
              </TableCell>
              <TableCell align="left">
                <Box sx={{ display: 'flex', alignItems: 'center', width: `${width}%` }}>
                  <Box sx={{ flexGrow: 1, flexShrink: 0, mr: 1 }}>
                    <LinearProgress
                      sx={{
                        height: 15,
                        borderRadius: 10,
                        [`& .${linearProgressClasses.bar}`]: {
                          borderRadius: 5,
                          backgroundColor: '#000',
                        },
                        minWidth: '5px',
                      }}
                      variant="determinate"
                      aria-label=""
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {row.count}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
