import {
  type CampaignActivityData,
  type SMSCampaign,
  type SMSCampaignActivityItem,
  useGetSMSSubscriberCountQuery,
} from '@sevenrooms/core/api'
import { MarketingCampaignTypeEnum, SMSCampaignStatusEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, Collapsible, DividerLine, Flex, HStack, Loader } from '@sevenrooms/core/ui-kit/layout'
import {
  campaignCenterMessages,
  CampaignPerformanceChart,
  CampaignPerformanceFilters,
  type CampaignPerformanceProps,
  CampaignPerformanceStatistics,
  type GraphableActivity,
  OneTimeCampaignPerformanceFilters,
  useSMSCampaignPerformanceContext,
} from '@sevenrooms/marketing'
import { addUpActivities, getPercentageOfTotal, getReservationSearchURL } from '@sevenrooms/marketing/utils'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { smsCenterMessages } from '../../locales/smsCenterMessages'

const SMS_STATS_WITH_ORDERS: GraphableActivity[] = ['reservations', 'orders', 'clicks']
const SMS_STATS_WITHOUT_ORDERS: GraphableActivity[] = ['reservations', 'clicks']
const ONE_TIME_CAMPAIGN_STATUSES = [SMSCampaignStatusEnum.COMPLETE, SMSCampaignStatusEnum.ACTIVE, SMSCampaignStatusEnum.DRAFT]
const ONGOING_CAMPAIGN_STATUSES = [SMSCampaignStatusEnum.ACTIVE, SMSCampaignStatusEnum.INACTIVE, SMSCampaignStatusEnum.DRAFT]

export function SMSCampaignPerformance({ collapsed, onCollapsedChange, isOneTimeCampaignList, messages }: CampaignPerformanceProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const {
    filteredCampaigns,
    isCampaignQueryFetching,
    campaignActivityData,
    isActivityQueryFetching,
    activityQueryError,
    startDate,
    endDate,
    dateFilter,
    isPOSActivated,
    averageSpendPerCover,
    searchValue,
  } = useSMSCampaignPerformanceContext()

  const {
    data: subscriberCountData,
    isFetching: isSubscriberCountQueryFetching,
    isError: isSubscriberCountQueryError,
  } = useGetSMSSubscriberCountQuery({ venueId: venue.id })

  const cards = venue
    ? getActivityDifferences(
        formatMessage,
        nav,
        getReservationSearchURL(
          venue.urlKey,
          startDate,
          endDate,
          dateFilter,
          searchValue,
          isOneTimeCampaignList ? MarketingCampaignTypeEnum.ONE_TIME : MarketingCampaignTypeEnum.ONGOING,
          'sms'
        ),
        venue.currencySymbol,
        venue.locale.replace('_', '-'),
        venue.isMMSEnabled,
        filteredCampaigns,
        campaignActivityData,
        isOneTimeCampaignList,
        isPOSActivated,
        averageSpendPerCover,
        subscriberCountData?.count,
        isSubscriberCountQueryError
      )
    : []

  return (
    <>
      <Box pl="m" pr="m" pt="m" mb="m">
        {isOneTimeCampaignList ? (
          <OneTimeCampaignPerformanceFilters
            statuses={ONE_TIME_CAMPAIGN_STATUSES}
            messages={messages}
            usePerformanceContext={useSMSCampaignPerformanceContext}
          />
        ) : (
          <CampaignPerformanceFilters
            statuses={ONGOING_CAMPAIGN_STATUSES}
            messages={messages}
            usePerformanceContext={useSMSCampaignPerformanceContext}
          />
        )}
      </Box>

      <DividerLine margin="none" />

      <Box maxWidth="100%" pl="m" pr="m">
        <Flex width="100%" minHeight="85px" alignItems="center" justifyContent="center">
          {isActivityQueryFetching || isCampaignQueryFetching || isSubscriberCountQueryFetching ? (
            <Loader />
          ) : (
            <HStack justifyContent="space-between" flexBasis="100%">
              <CampaignPerformanceStatistics cards={cards} />
              <Button
                variant="tertiary"
                data-test="sr-button-expand-chart"
                icon={collapsed ? 'VMSWeb-chevron-down' : 'VMSWeb-chevron-up'}
                onClick={() => {
                  onCollapsedChange(!collapsed)
                }}
                noPadding
              />
            </HStack>
          )}
        </Flex>
        <Collapsible collapsed={collapsed}>
          <Box width="100%" mt="m">
            <CampaignPerformanceChart
              histogram={campaignActivityData?.histogram || []}
              isFetching={isActivityQueryFetching}
              error={activityQueryError}
              messages={messages}
              usePerformanceContext={useSMSCampaignPerformanceContext}
              availableStatistics={venue.isSevenRoomsOrderingEnabled ? SMS_STATS_WITH_ORDERS : SMS_STATS_WITHOUT_ORDERS}
            />
          </Box>
        </Collapsible>
      </Box>
    </>
  )
}

function buildCards(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  this: any,
  isMMSEnabled: boolean,
  currentActivities: SMSCampaignActivityItem,
  reservationsLink: string,
  currencySymbol: string,
  locale: string,
  isOneTimeCampaignList?: boolean,
  isPOSActivated?: boolean,
  averageSpendPerCover?: number,
  subscriberCount?: number,
  isSubscriberCountQueryError?: boolean
) {
  const totalRevenue =
    !isPOSActivated && averageSpendPerCover ? currentActivities.covers * averageSpendPerCover : currentActivities.totalRevenue

  const subscriberContents =
    isSubscriberCountQueryError || typeof subscriberCount !== 'number' ? '-' : subscriberCount.toLocaleString(locale)
  return isOneTimeCampaignList
    ? [
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceSubscribers),
          // HStack prevents "bouncing" based on variations in text width between the states
          value: (
            <HStack width="90px" alignItems="center" justifyContent="center">
              {subscriberContents}
            </HStack>
          ),
        },
        isMMSEnabled
          ? {
              name: this.formatMessage(smsCenterMessages.campaignPerformanceRecipientsMMS),
              value: currentActivities.numMessages.toLocaleString(locale),
            }
          : {
              name: this.formatMessage(smsCenterMessages.campaignPerformanceRecipientsSMS),
              value: currentActivities.numSegments.toLocaleString(locale),
            },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceClicked),
          value: getPercentageOfTotal(currentActivities.clicks, currentActivities.recipients),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceTotalRevenue),
          value: `${currencySymbol}${Math.round(totalRevenue).toLocaleString(locale)}`,
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceReservations),
          value: currentActivities.reservations.toLocaleString(locale),
          linkConfig: reservationsLink
            ? {
                link: reservationsLink,
                isExternal: true,
              }
            : undefined,
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceCovers),
          value: currentActivities.covers.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceOrders),
          value: currentActivities.orders.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceUnsubscribes),
          value: currentActivities.unsubscribed.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceBounceRate),
          value: getPercentageOfTotal(currentActivities.bounces, currentActivities.recipients),
        },
      ]
    : [
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceRecipients),
          value: currentActivities.recipients.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceReservations),
          value: currentActivities.reservations.toLocaleString(locale),
          linkConfig: reservationsLink
            ? {
                link: reservationsLink,
                isExternal: true,
              }
            : undefined,
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceCovers),
          value: currentActivities.covers.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceOnlineOrders),
          value: currentActivities.orders.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceRevenue),
          value: `${currencySymbol}${Math.round(totalRevenue).toLocaleString(locale)}`,
        },
      ]
}

export function getActivityDifferences(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nav: any,
  reservationsLink: string,
  currencySymbol: string,
  locale: string,
  isMMSEnabled: boolean,
  filteredCampaigns?: SMSCampaign[],
  data?: CampaignActivityData,
  isOneTimeCampaignList?: boolean,
  isPOSActivated?: boolean,
  averageSpendPerCover?: number,
  subscriberCount?: number,
  isSubscriberCountQueryError?: boolean
) {
  if (data && filteredCampaigns) {
    const { campaignActivity: currentAct } = data
    const currentActTotal: SMSCampaignActivityItem = {
      ...addUpActivities(currentAct),
      numSegments: filteredCampaigns.reduce((prevVal, campaign) => prevVal + campaign.numSegments, 0),
      numMessages: filteredCampaigns.reduce((prevVal, campaign) => prevVal + campaign.numMessages, 0),
    }
    return buildCards.call(
      { formatMessage, nav },
      isMMSEnabled,
      currentActTotal,
      reservationsLink,
      currencySymbol,
      locale,
      isOneTimeCampaignList,
      isPOSActivated,
      averageSpendPerCover,
      subscriberCount,
      isSubscriberCountQueryError
    )
  }
  return []
}
