import { useMemo } from 'react'
// eslint-disable-next-line import/no-relative-packages
import { TransitionBlockerWithOldRouterSupport } from '../../../../application/site/static/app/manager/lib/components/TransitionBlockerWithOldRouterSupport'
import { usePromoCodeContext } from '../hooks/usePromoCodeContext'
import { AdvancedSettingsSection } from './AdvancedSettingsSection'
import { GeneralSection } from './GeneralSection'
import { LifespanSection } from './LifespanSection'
import { PromoTypesSection } from './PromoTypesSection'
import { UnsavedChangesModal } from './UnsavedChangesModal'
import { UsageSection } from './UsageSection'

export function PromoCodeForm() {
  const { widgets, containsDirtyChanges } = usePromoCodeContext()

  const showAdvancedSettings = useMemo(() => {
    const selected = widgets.filter(option => option.checked)
    return selected.length === 1 && selected[0]?.value !== 'ORDERING'
  }, [widgets])

  return (
    <>
      <GeneralSection />
      <PromoTypesSection />
      <UsageSection />
      <LifespanSection />
      {showAdvancedSettings && <AdvancedSettingsSection />}
      <TransitionBlockerWithOldRouterSupport modal={<UnsavedChangesModal />} isBlocked={containsDirtyChanges} />
    </>
  )
}
