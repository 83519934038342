import React, { type PropsWithChildren, useEffect, useMemo, useState } from 'react'
import type { TableItem } from '@sevenrooms/core/api'
import { ExperienceLinkTypeEnum, type SeatingAreaToTables } from '@sevenrooms/core/domain'
import { useWatch, type UseForm, type z, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormNumberInput, RadioGroup, Checkbox } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { getAccessRuleDefaults } from '@sevenrooms/mgr-access-rules-slideout/AccessRule.zod'
import { SeatingAreasFields } from '@sevenrooms/mgr-access-rules-slideout/components/SeatingAreas/components'
import { useAccessRuleContext } from '@sevenrooms/mgr-access-rules-slideout/components/shared'
import { useExperienceFromTemplateContext } from './ExperienceFromTemplateContext'
import { messages } from './locales/experienceStepperFlow.locales'
import { NewAccessRuleReview } from './NewAccessRuleReview'
import type { CreateExperienceFromTemplateFormData } from './CreateExperienceFromTemplateForm.zod'

export function NewAccessRuleCustomization({ form }: { form: UseForm<z.ZodType<CreateExperienceFromTemplateFormData>> }) {
  const {
    shiftSeatingAreas,
    setFlowCompleted,
    isAccessRuleCustomizationInitialPart,
    setIsAccessRuleCustomizationInitialPart,
    setFlowStep,
    setUseSaveButton,
  } = useExperienceFromTemplateContext()

  useEffect(() => {
    if (isAccessRuleCustomizationInitialPart) {
      setFlowCompleted(false)
    } else {
      setFlowCompleted(true)
    }
  }, [isAccessRuleCustomizationInitialPart, setFlowCompleted])

  return isAccessRuleCustomizationInitialPart ? (
    <AccessRuleCustomization form={form} shiftSeatingAreas={shiftSeatingAreas} />
  ) : (
    <NewAccessRuleReview
      form={form}
      setFlowStep={setFlowStep}
      setIsAccessRuleCustomizationInitialPart={setIsAccessRuleCustomizationInitialPart}
      setUseSaveButton={setUseSaveButton}
    />
  )
}

function AccessRuleCustomization({
  form,
  shiftSeatingAreas,
}: {
  form: UseForm<z.ZodType<CreateExperienceFromTemplateFormData>>
  shiftSeatingAreas: { id: string; name: string; seatingAreas: SeatingAreaToTables[]; tables: TableItem[] }[]
}) {
  const { formatMessage } = useLocales()
  const context = useAccessRuleContext()
  const { seatingAreasAndTableCombinations: defaultSeatingAreasAndTableCombinations } = getAccessRuleDefaults({
    ...context,
    defaultValues: {},
  })
  const { field, getValues, setValue } = form
  const values = getValues()
  const seatingAreasAndTableCombinations = useWatch(field.prop('accessRule.seatingAreasAndTableCombinations.selection'))

  const [seatingAreas, setSeatingAreas] = useState(values.accessRule.seatingAreasAndTableCombinations.selection)

  const useShiftSeatingAreas = useMemo(() => {
    const selectedSeatingAreas = values.accessRule.seatingAreasAndTableCombinations.selection
    const defaultSeatingAreas = defaultSeatingAreasAndTableCombinations.selection
    if (selectedSeatingAreas.length !== defaultSeatingAreas.length) {
      return false
    }
    for (let i = 0; i < selectedSeatingAreas.length; i + 1) {
      if (selectedSeatingAreas[i] !== defaultSeatingAreas[i]) {
        return false
      }
    }
    return true
  }, [defaultSeatingAreasAndTableCombinations.selection, values.accessRule.seatingAreasAndTableCombinations.selection])

  const [showSeatingAreasSelect, setShowSeatingAreasSelect] = useState(!useShiftSeatingAreas)

  const onUseShiftSeatingAreasSettings = () => {
    if (showSeatingAreasSelect) {
      setSeatingAreas(seatingAreasAndTableCombinations)
    }
    setValue(
      'accessRule.seatingAreasAndTableCombinations.selection',
      showSeatingAreasSelect ? defaultSeatingAreasAndTableCombinations.selection : seatingAreas
    )
    setShowSeatingAreasSelect(!showSeatingAreasSelect)
  }

  return (
    <VStack pt="xxl" pb="xxl" spacing="l" width="496px" data-test="create-new-access-rule-customization-step-content">
      <Text textStyle="h2" textAlign="center">
        {formatMessage(messages.newAccessRuleCustomizationStepTitle, {
          offerName: (
            <Text textStyle="h2" fontWeight="bold">
              "{values.name}"
            </Text>
          ),
        })}
      </Text>
      <CustomizationField label={formatMessage(messages.newAccessRuleCustomizationOfferType)}>
        <CustomizationOfferTypeOptions field={field.prop('accessType')} />
      </CustomizationField>
      <CustomizationField label={formatMessage(messages.newAccessRuleCustomizationPartySizeExpanded)}>
        <HStack spacing="s">
          <VStack spacing="xs" width="100%">
            <Text textStyle="body1">{formatMessage(messages.newAccessRuleCustomizationPartySizeMin)}</Text>
            <FormNumberInput field={field.prop('accessRule.partySize.min')} decimalScale={0} width="100%" />
          </VStack>
          <VStack spacing="xs" width="100%">
            <Text textStyle="body1">{formatMessage(messages.newAccessRuleCustomizationPartySizeMax)}</Text>
            <FormNumberInput field={field.prop('accessRule.partySize.max')} decimalScale={0} width="100%" />
          </VStack>
        </HStack>
      </CustomizationField>
      <VStack>
        <CustomizationField
          label={formatMessage(messages.newAccessRuleCustomizationSeatingAreas)}
          noBottomBorderRadius={showSeatingAreasSelect}
        >
          <Checkbox onChange={onUseShiftSeatingAreasSettings} checked={!showSeatingAreasSelect}>
            <Text textStyle="body1">{formatMessage(messages.followShiftSettings)}</Text>
          </Checkbox>
        </CustomizationField>
        {showSeatingAreasSelect && (
          <CustomizationAdditionalSection>
            <SeatingAreasFields
              field={field.prop('accessRule.seatingAreasAndTableCombinations')}
              shiftSeatingAreas={shiftSeatingAreas}
              conflict={<></>}
              showRestrictionCheckbox={false}
            />
          </CustomizationAdditionalSection>
        )}
      </VStack>
    </VStack>
  )
}

function CustomizationOfferTypeOptions({ field }: { field: Field<number> }) {
  const { formatMessage } = useLocales()
  const choices = useMemo(
    () => [
      {
        value: ExperienceLinkTypeEnum.OPEN_ACCESS,
        label: <Text textStyle="body1">{formatMessage(messages.newAccessRuleCustomizationOfferTypePublic)}</Text>,
      },
      {
        value: ExperienceLinkTypeEnum.EXCLUSIVE_ACCESS,
        label: <Text textStyle="body1">{formatMessage(messages.newAccessRuleCustomizationOfferTypeExclusive)}</Text>,
      },
    ],
    [formatMessage]
  )
  return <RadioGroup data-test="offer-access-type-selector" choices={choices} field={field} />
}

function CustomizationFieldWrapper({ children, noBottomBorderRadius }: PropsWithChildren<{ noBottomBorderRadius?: boolean }>) {
  return (
    <VStack
      spacing="s"
      borderWidth="s"
      borderBottomLeftRadius={noBottomBorderRadius ? 'none' : 's'}
      borderBottomRightRadius={noBottomBorderRadius ? 'none' : 's'}
      borderTopLeftRadius="s"
      borderTopRightRadius="s"
      borderColor="dividerLines"
      p="m"
    >
      {children}
    </VStack>
  )
}

function CustomizationAdditionalSection({ children }: PropsWithChildren<{}>) {
  return (
    <VStack
      spacing="s"
      borderWidth="s"
      borderBottomLeftRadius="s"
      borderBottomRightRadius="s"
      borderColor="dividerLines"
      backgroundColor="secondaryBackground"
      p="m"
    >
      {children}
    </VStack>
  )
}

function CustomizationField({
  label,
  noBottomBorderRadius,
  children,
}: PropsWithChildren<{ label: string; noBottomBorderRadius?: boolean }>) {
  return (
    <CustomizationFieldWrapper noBottomBorderRadius={noBottomBorderRadius}>
      <Text textStyle="body1Bold">{label}</Text>
      {children}
    </CustomizationFieldWrapper>
  )
}
