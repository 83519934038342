import { useMemo } from 'react'
import { type CreateEditExperienceApi, FileService } from '@sevenrooms/core/api'
import {
  ExperienceVisibilityEnum,
  type ImageObject,
  DaysOfTheWeekEnum,
  ShiftCategoryEnum,
  PaymentRuleTypeEnum,
  ExperienceLinkTypeEnum,
  ServiceChargeEnum,
  type ShiftCategory,
} from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useImageForm } from '@sevenrooms/core/ui-kit/form'
import { getInitialAccessRule, getInitialState, useAccessRuleForm } from '@sevenrooms/mgr-access-rules-slideout/AccessRule.zod'
import { PartySizeLocales } from '@sevenrooms/mgr-access-rules-slideout/components/PartySize/PartySize.locales'
import { PaymentPolicyLocales } from '@sevenrooms/mgr-access-rules-slideout/components/PaymentPolicy/PaymentPolicy.locales'
import { getInitialSchedule } from '@sevenrooms/mgr-access-rules-slideout/components/Schedule/Schedule.zod'
import { getSeatingAreasByDefaultAreasList } from '@sevenrooms/mgr-access-rules-slideout/components/SeatingAreas/SeatingAreas.zod'
import type { AccessRuleContext } from '@sevenrooms/mgr-access-rules-slideout/components/shared'
import { messages } from './locales/experienceStepperFlow.locales'
import type { ExperienceTemplateProps } from '../ExperienceTemplatesGallery'

export const useCreateExperienceFromTemplateForm = () => {
  const image = useImageForm()
  const accessRule = useAccessRuleForm()

  const { formatMessage } = useLocales()
  const fieldLengthError = formatMessage(messages.fieldLengthError, { minSize: 1 })

  return useMemo(
    () =>
      z.object({
        templateId: z.string(),
        name: z.string().trim().min(1, fieldLengthError),
        description: z.string().trim().min(1, fieldLengthError),
        accessRule: accessRule.superRefine(({ schedule, partySize, paymentPolicy }, ctx) => {
          if (schedule.selectedDays.length === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(messages.daysOfTheWeekRequired),
              path: ['schedule', 'selectedDays'],
            })
          }
          if (partySize.min && partySize.max && partySize.max < partySize.min) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PartySizeLocales.maxGreaterThanMin),
              path: ['partySize', 'max'],
            })
          }
          if (paymentPolicy.paymentRule === PaymentRuleTypeEnum.save_for_later) {
            if (paymentPolicy.partySizeType === 'gt' && paymentPolicy.partySizeMin == null) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(PaymentPolicyLocales.partySizeRequired),
                path: ['paymentPolicy', 'partySizeMin'],
              })
            }
          }
          if (
            paymentPolicy.charges.applyServiceCharge &&
            paymentPolicy.charges.serviceChargeType === ServiceChargeEnum.SPECIFIC_SERVICE_CHARGE &&
            !paymentPolicy.charges.serviceChargePercent
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PaymentPolicyLocales.serviceChargeRequired),
              path: ['paymentPolicy', 'charges', 'serviceChargePercent'],
            })
          }
          if (
            paymentPolicy.charges.applyGratuity &&
            paymentPolicy.charges.gratuityType === 'SPECIFIC_GRATUITY' &&
            !paymentPolicy.charges.gratuityPercent
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PaymentPolicyLocales.gratuityRequired),
              path: ['paymentPolicy', 'charges', 'gratuityPercent'],
            })
          }
          if (paymentPolicy.charges.applyTax && !paymentPolicy.charges.taxId) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PaymentPolicyLocales.taxRequired),
              path: ['paymentPolicy', 'charges', 'taxId'],
            })
          }
        }),
        price: z.string().trim(),
        heroImage: image.nullable(),
        linkedAccessRuleIds: z.array(z.string()),
        allowChannelsWithoutCCHolds: z.boolean(),
        accessType: z.number(),
      }),
    [accessRule, fieldLengthError, formatMessage, image]
  )
}
export type CreateExperienceFromTemplateFormData = ZodSchema<typeof useCreateExperienceFromTemplateForm>

export async function formToExperience({
  formData,
  onError,
}: {
  formData: CreateExperienceFromTemplateFormData
  onError: () => void
}): Promise<CreateEditExperienceApi> {
  const heroImageUrlKey = async () => {
    try {
      const fileData = formData.heroImage?.fileData
      if (fileData) {
        const { urlKey: heroImageUrlKey } = await FileService.uploadFile({
          file: fileData,
          rurl: Math.random().toString(),
        })
        return heroImageUrlKey
      }
      return undefined
    } catch {
      onError()
      return undefined
    }
  }

  return {
    name: formData.name,
    description: formData.description || '',
    price_description: formData?.price || '',
    template_id: 'default',
    title: formData.name,
    default_party_size: formData.accessRule.partySize?.min || DefaultPartySize,
    hero_image: formData.heroImage ? getImage(await heroImageUrlKey(), formData.heroImage) : {},
    image_list: [],
    visibility: ExperienceVisibilityEnum.PUBLIC,
    unlinked_access_rule_ids: [],
    linked_access_rule_ids: formData.linkedAccessRuleIds,
    unlinked_event_ids: [],
    linked_event_ids: [],
    offer_type: 'EXPERIENCE',
    status: 'ACTIVE',
    hide_on_directory: false,
    hide_price_description: !formData?.price,
    offer_template_id: formData.templateId,
  }
}

function getImage(url: string | undefined, formImage: ImageObject | null) {
  if (formImage) {
    const imageInfo = {
      crop_info: formImage.crop,
      name: formImage.name,
    }

    if (url) {
      // new uploaded image
      return {
        ...imageInfo,
        raw_url_key: url,
      }
    }

    // template image
    const imageUrl = formImage.rawUrl
    return {
      ...imageInfo,
      raw_url_key: imageUrl,
      photo_dict: { large: imageUrl, small: imageUrl },
    }
  }
  return null
}

export function useDefaultValues(
  offerTemplate: ExperienceTemplateProps,
  venueName: string,
  venueCurrency: string,
  startOfDayTime: string,
  accessRuleContext: AccessRuleContext
) {
  const offerName = offerTemplate.offerName?.replace('(name)', venueName)
  const partySize = offerTemplate.partySize?.split(',')
  const minPartySize = partySize ? partySize[0]?.split(':')[1] : DefaultPartySize
  const maxPartySize = partySize ? partySize[1]?.split(':')[1] : DefaultPartySize

  const initialAccessRule = getInitialAccessRule({ ...accessRuleContext })

  const daysOfTheWeek = offerTemplate.daysOfTheWeek
    ?.split(',')
    .map(item => {
      const day = item.trim().toUpperCase()
      return Object.keys(DaysOfTheWeekEnum).indexOf(day)
    })
    .filter(i => i + 1)

  const shifts = Object.keys(ShiftCategoryEnum)
  const shiftCategories = offerTemplate.shifts
    ?.split(',')
    .map(item => {
      const shift = item.trim().toUpperCase()
      if (shifts.includes(shift)) {
        return shift
      }
      return undefined
    })
    .filter(i => !!i)

  const startDate = getStartDate(startOfDayTime)

  const schedule = getInitialSchedule({
    startDate,
    shiftCategories: (shiftCategories?.length ? shiftCategories : shifts) as ShiftCategory[],
    startOfDayTime: '',
    selectedDays: daysOfTheWeek,
  })

  const defaultSeatingAreas = offerTemplate.seatingAreas
    ?.toLowerCase()
    .split(',')
    .map(item => item.trim())
  const seatingAreas = getSeatingAreasByDefaultAreasList(
    accessRuleContext.allShifts,
    accessRuleContext.seatingAreaToTables,
    defaultSeatingAreas
  )

  const accessRule = getInitialState({
    initialSchedule: schedule,
    accessRuleDefaults: initialAccessRule,
    editPhoto: '',
    name: offerName,
    shiftCategories: (shiftCategories?.length ? shiftCategories : shifts) as ShiftCategory[],
    startDate,
    startOfDayTime,
    ...accessRuleContext,
    currencyCode: venueCurrency,
    partySize: { min: minPartySize ? +minPartySize : DefaultPartySize, max: maxPartySize ? +maxPartySize : DefaultPartySize },
    seatingAreas,
    isAccessRuleFromTemplate: true,
  })
  accessRule.guestFacing.timeslotDescription = offerName

  return useMemo(
    () => ({
      templateId: offerTemplate.id,
      name: offerName,
      description: '<p>description</p>'.replace('description', offerTemplate.offerDescription),
      price: '',
      heroImage: offerTemplate.offerImage
        ? {
            rawUrl: offerTemplate.offerImage,
            name: offerTemplate?.offerImageFileName || ' ',
            crop: {
              height: 100,
              width: 100,
              unit: '%',
              x: 0,
              y: 0,
            },
          }
        : null,
      linkedAccessRuleIds: [],
      allowChannelsWithoutCCHolds: false,
      newAccessRule: null,
      accessType: ExperienceLinkTypeEnum.OPEN_ACCESS,
      accessRule,
    }),
    [accessRule, offerName, offerTemplate.id, offerTemplate.offerDescription, offerTemplate.offerImage, offerTemplate?.offerImageFileName]
  )
}

function getStartDate(startOfDayTime: string) {
  const todayDate = new Date()
  const venueStartDayTime = (startOfDayTime || '06:00:00')?.split(':')?.map(item => +item) as [
    hour: number,
    minutes: number,
    seconds: number
  ]
  todayDate.setHours(...venueStartDayTime)
  return todayDate
}

export type Availabilities = 'linkToExistingAccessRule' | 'createNewAccessRule'
const DefaultPartySize = 2
