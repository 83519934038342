import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  useGetEmailCampaignByIdQuery,
  useGetExperiencesQuery,
  useGetVenueProfileQuery,
  useGetClientTagGroupsQuery,
  useGetByTemplateIdQuery,
  useGetGroupVenuesQuery,
  type OneTimeEmailCampaignType,
  type OneTimeEmailCampaignTemplate,
} from '@sevenrooms/core/api'
import { ExperienceStatusEnum } from '@sevenrooms/core/domain'
import { useDestination } from '@sevenrooms/core/navigation'
import { TopolContextProvider } from '@sevenrooms/core/ui-kit/form'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { OneTimeEmailCampaignForm } from '../../components'

export function OneTimeEmailCampaign() {
  const { venue, venueId } = useVenueContext()
  const venueKey = venue?.urlKey ?? ''

  const { params: editParams } = useDestination(routes.manager2.marketing.oneTimeEmailCenter.editOneTimeEmail)
  const { query: createQuery } = useDestination(routes.manager2.marketing.oneTimeEmailCenter.createOneTimeEmail)

  const { campaignToDuplicateId, parentTemplateId } = createQuery
  const campaignId = editParams ? editParams.campaignId : campaignToDuplicateId

  const { data: autoTags, isFetching: isVenueClientTagsFetching } = useGetClientTagGroupsQuery(venueKey ? { venueKey } : skipToken)
  const { data: venueProfile, isFetching: isVenueProfileFetching } = useGetVenueProfileQuery({ venueId })
  const { data: experiencesData, isFetching: isExperiencesDataFetching } = useGetExperiencesQuery({
    venueId,
    status: ExperienceStatusEnum.ACTIVE,
  })
  const { data, isFetching: isEmailCampaignFetching } = useGetEmailCampaignByIdQuery(
    campaignId && venueId ? { campaignId, venueId } : skipToken
  )
  const { data: parentTemplate, isFetching: isParentTemplateFetching } = useGetByTemplateIdQuery(
    parentTemplateId && venueId ? { campaignId: parentTemplateId, venueId } : skipToken
  )
  const { data: groupVenuesData, isFetching: isVenueGroupsFetching } = useGetGroupVenuesQuery({
    venueId,
    isStandaloneMarketing: true,
  })
  const isFetching =
    isVenueProfileFetching ||
    isExperiencesDataFetching ||
    isEmailCampaignFetching ||
    isVenueClientTagsFetching ||
    isParentTemplateFetching ||
    isVenueGroupsFetching
  const isDuplicate = !!campaignToDuplicateId

  return isFetching || !venueProfile || !experiencesData || !autoTags || !groupVenuesData ? (
    <Loader />
  ) : (
    <TopolContextProvider>
      <OneTimeEmailCampaignForm
        emailCampaign={data?.emailCampaign as OneTimeEmailCampaignType}
        venueProfile={venueProfile}
        experiencesData={experiencesData}
        isDuplicate={isDuplicate}
        isEdit={!!(campaignId && !isDuplicate)}
        autoTags={autoTags}
        parentTemplate={parentTemplate as OneTimeEmailCampaignTemplate}
        groupVenues={
          venueProfile.productionProvisionPackage === 'MARKETING_ALPHA' || venueProfile.productionProvisionPackage === 'MARKETING_BETA'
            ? groupVenuesData.groupVenues
            : []
        }
      />
    </TopolContextProvider>
  )
}
