import { useLocales } from '@sevenrooms/core/locales'
import { Label, FormInput, FormNumberInput } from '@sevenrooms/core/ui-kit/form'
import { Grid } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { venueProfileMessages } from '../../locales'
import type { VenueInformationFieldKey, VenueInformationFormFields } from './VenueInformationForm.zod'

export interface VenueInformationFormProps {
  fields: VenueInformationFormFields
}

const venueInformationConfig = [
  { fieldKey: 'address', labelMessage: venueProfileMessages.addressLabel },
  { fieldKey: 'crossStreet', labelMessage: venueProfileMessages.crossStreetLabel },
  { fieldKey: 'city', labelMessage: venueProfileMessages.cityLabel },
  { fieldKey: 'state', labelMessage: venueProfileMessages.stateProvinceLabel },
  { fieldKey: 'postalCode', labelMessage: venueProfileMessages.postalCodeLabel },
  { fieldKey: 'country', labelMessage: venueProfileMessages.countryLabel },
  { fieldKey: 'phoneNumber', labelMessage: venueProfileMessages.phoneLabel },
  { fieldKey: 'contactEmail', labelMessage: venueProfileMessages.primaryContactLabel },
  { fieldKey: 'website', labelMessage: venueProfileMessages.websiteLabel, withPlaceholder: true },
  { fieldKey: 'menuLink', labelMessage: venueProfileMessages.menuLinkLabel, withPlaceholder: true },
  { fieldKey: 'gmapsLink', labelMessage: venueProfileMessages.googleMapsLinkLabel, withPlaceholder: true },
  { fieldKey: 'reservationWidgetLink', labelMessage: venueProfileMessages.bookingLinkLabel, withPlaceholder: true },
]

export function VenueInformationForm({ fields }: VenueInformationFormProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const placeholder = formatMessage(venueProfileMessages.protocolPlaceholder)

  return (
    <Grid gridTemplateColumns="1fr 1fr" columnGap="lm" rowGap="m">
      {venueInformationConfig.map(({ fieldKey, labelMessage, withPlaceholder = false }) => (
        <Label key={fieldKey} primary={formatMessage(labelMessage)}>
          <FormInput field={fields.prop(fieldKey as VenueInformationFieldKey)} {...(withPlaceholder && { placeholder })} />
        </Label>
      ))}
      <Label primary={formatMessage(venueProfileMessages.avgSpendCoverLabel)}>
        <FormNumberInput field={fields.prop('averageSpendPerCover')} prefix={venue.currencySymbol} />
      </Label>
      <Label primary={formatMessage(venueProfileMessages.avgSpendOrderLabel)}>
        <FormNumberInput field={fields.prop('averageSpendPerOrder')} prefix={venue.currencySymbol} />
      </Label>
    </Grid>
  )
}
