import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { PulsatingDots } from 'mgr/layout/StyledComponentUtils'
import Theme from 'mgr/layout/Theme'
import {
  toggleCustomAutotagSlideout,
  trySaveCustomAutotag,
  tryDeleteAutotag,
  toggleDeactivateWarning,
  updateDeactivateFunction,
} from 'mgr/pages/single-venue/marketing/actions/Autotags'
import {
  Content,
  Heading,
  SubHead,
  Title,
  TextIndicator,
  LastUpdated,
} from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import { ActiveLabelTextInput } from 'mgr/pages/single-venue/marketing/components/auto-tags/custom-auto-tags/CustomAutotagActiveLabelTextInput'
import CustomAutotagConfigEdit from 'mgr/pages/single-venue/marketing/components/auto-tags/custom-auto-tags/CustomAutotagConfigEdit'
import { DisplayArea } from 'mgr/pages/single-venue/marketing/components/MarketingWrap'
import {
  selectCustomAutotagObjects,
  selectCustomAutotagDefaultValueMap,
  selectClientTagsOptionsList,
  selectCustomAutotagEditState,
} from 'mgr/pages/single-venue/marketing/selectors/Autotags'
import { VmsIcons, StyledVmsIconSH } from 'svr/common/VmsIcons'
import VmsCheckbox from 'svr/component-lib/Generic/Checkbox/VmsCheckbox'
import RadioList from 'svr/component-lib/Generic/RadioButtons/RadioList'
import { AutotagPermissions } from '../components/auto-tags/AutotagPermissions'
import { CustomAutotagTierAdditionalOptions } from '../components/auto-tags/custom-auto-tags/CustomAutotagTierAdditionalOptions'
import { transformConfigStateForClient, validateCustomAutotagConfigState } from '../utils/custom-autotags'
import AutotagSlideout from './AutotagSlideout'

const NEW_CUSTOM_TIER_ID_PLACEHOLDER = 'REPLACE_WITH_CUSTOM_TIER_ID'

const InnerContent = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: ${props => (props.flexStart ? 'flex-start' : 'center')};
  align-items: ${props => (props.flexStart ? 'flex-start' : 'center')};
  flex-direction: column;
  padding: ${props => props.padding || '15px 28px'};
  margin: 10px 0;
`

const InnerContentLabel = styled.span`
  display: block;
  width: 100%;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #888c92;
`

const SmallInnerContentLabel = styled.span`
  display: block;
  color: #888c92;
  font-size: 12px;
  font-weight: bold;
  align-self: flex-start;
  margin-bottom: 10px;
`
const InnerContentDescription = styled.span`
  display: block;
  width: 100%;
  margin-top: -16px;
  font-size: 14px;
  font-weight: 400;
  color: ${Theme.color.secondaryText};
`

const SlideoutTitle = styled.div`
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  height: 42px;
  line-height: 42px;
  margin-left: 20px;
`

const ScopeDesc = styled.ul`
  margin: 0 0 0 15px;
  line-height: 19px;
  color: #565C63
  font-weight: bold;
`

const ScopeDescItem = styled.li``

const Hr = styled.hr`
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0 20px;
`

export const ActionArea = styled.div`
  min-height: 90px;
  display: flex;
  background-color: white;
  justify-content: flex-end;
  align-items: center;
`

export const CreateButton = styled.div`
  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.deactivated : '#0abcc2')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled }) => disabled && 'pointer-events: none;'},
  border-radius: 5px;
  color: #ffffff;
  display: block;
  font-size: 15px;
  line-height: 64px;
  margin: 8px;
  text-align: center;
  text-trasform: uppercase;
  height: 42px;
  width: 127px;
  line-height: 42px;
  margin-right: 36px;
`
const ActionsContainer = styled.div`
  display: flex;
  margin: 0px;
  flex-direction: row;
  align-items: center;
`

const Delete = styled.div`
  color: ${props => props.theme.color.errorAccent};
  cursor: pointer;
  font-size: ${props => props.theme.fontSize.body};
  margin-right: 30px;
  text-align: center;
  text-decoration: underline;
  width: 120px;
`

const VmsCheckboxWrapper = styled.div`
  padding: 0px 28px 24px 28px;
`

const RadioListValueTypes = {
  LOCAL: 'LOCAL',
  GLOBAL: 'GLOBAL',
}

const getDefaultConfigItemState = () => ({
  condition: null,
  operator: null,
  value: null,
  chain: [],
})

const getDefaultState = () => ({
  tagName: '',
  scope: RadioListValueTypes.LOCAL,
  isForMarketingSegmentation: false,
  triedSaving: false,
  configState: [[getDefaultConfigItemState()]],
  isAutotagNameValid: true,
  isConfigStateValid: true,
  notesByTagName: {},
  perksByTagName: {},
})

const getInitialEditState = ({ tierConfig, autotagConfig, perksByTagName, notesByTagName }) => ({
  tagName: tierConfig.name_display,
  scope: autotagConfig.is_global ? RadioListValueTypes.GLOBAL : RadioListValueTypes.LOCAL,
  isForMarketingSegmentation: tierConfig.is_for_marketing_segmentation,
  configState: transformConfigStateForClient(tierConfig.custom_config_tree_client),
  is_active: tierConfig.is_active,
  isAutotagNameValid: true,
  isConfigStateValid: true,
  notesByTagName: notesByTagName || {},
  perksByTagName: perksByTagName || {},
})

class CustomAutotagEditor extends React.PureComponent {
  constructor(props) {
    super(props)
    this.initialState = this.props.isEditMode ? getInitialEditState(this.props.customAutotagEditState) : getDefaultState()
    this.state = _.cloneDeep(this.initialState)
    this.viewID = 'displayArea'
  }

  componentDidMount() {
    window.document.body.addEventListener('keydown', event => {
      if (event.keyCode === 27) {
        this.props.toggleCustomAutotagSlideout()
      }
    })
  }

  changeFieldHandler = (value, field) => this.setState({ [field]: value })

  changeCheckboxFieldHandler = field => {
    this.setState({ [field]: !this.state[field] })
  }

  autotagNameChangeHandler = value => this.setState({ tagName: value, isAutotagNameValid: true })

  toggleTagActive = () => this.changeFieldHandler(!this.state.is_active, 'is_active')

  getValueObjectTypeForConditionAndOperator = (conditionKey, operatorKey) => {
    const { conditionsMap, valueObjectsMap } = this.props.customAutotagObjects
    const conditionValueObjectTypes = conditionsMap[conditionKey].value_object_types
    return _.reduce(
      conditionValueObjectTypes,
      (accum, valueObjectType) => {
        let result = accum
        if (result) {
          return result
        }
        const valueObject = valueObjectsMap[valueObjectType]
        if (_.includes(valueObject.operator_types, operatorKey)) {
          result = valueObject.value_object_type
        }
        return result
      },
      null
    )
  }

  getDefaultValueForValueType = valueObjectType => {
    const { customAutotagObjects, defaultValueMap } = this.props
    const valueObject = customAutotagObjects.valueObjectsMap[valueObjectType]
    const defaultValue = valueObject.is_multi_select ? [] : defaultValueMap[valueObjectType]
    return defaultValue
  }

  getDefaultValueObjectAndOperatorForCondition = conditionType => {
    const { conditionsMap, valueObjectsMap } = this.props.customAutotagObjects
    const { defaultConditionToValueMap } = this.props
    if (defaultConditionToValueMap[conditionType]) {
      return defaultConditionToValueMap[conditionType]
    }
    const nextCondition = conditionsMap[conditionType]
    const nextValueObjectType = nextCondition.value_object_types[0]
    const nextValueObject = valueObjectsMap[nextValueObjectType]
    const nextOperator = nextValueObject.operator_types[0]
    return [nextValueObjectType, nextOperator]
  }

  getDefaultPropertiesForConditions = conditionType => {
    const retval = { condition: null, operator: null, value: null }
    retval.condition = conditionType
    const [nextValueObjectType, nextOperator] = this.getDefaultValueObjectAndOperatorForCondition(conditionType)
    retval.value = this.getDefaultValueForValueType(nextValueObjectType)
    retval.operator = nextOperator
    retval.chain = []
    return retval
  }

  getChainItems = conditionType => {
    const { conditionsMap } = this.props.customAutotagObjects
    const nextCondition = conditionsMap[conditionType]
    return _.map(nextCondition.valid_chain_conditions, this.getDefaultPropertiesForConditions)
  }

  getNextItemState = (prevItemState, updateObj) => {
    let nextItemState = { ...updateObj }
    if (prevItemState.condition !== updateObj.condition) {
      nextItemState = {
        ...nextItemState,
        ...this.getDefaultPropertiesForConditions(updateObj.condition),
      }
      nextItemState.chain = this.getChainItems(nextItemState.condition)
    } else if (prevItemState.operator !== updateObj.operator) {
      const currentValueObjectType = this.getValueObjectTypeForConditionAndOperator(prevItemState.condition, prevItemState.operator)
      const nextValueObjectType = this.getValueObjectTypeForConditionAndOperator(updateObj.condition, updateObj.operator)
      if (currentValueObjectType !== nextValueObjectType) {
        nextItemState.value = this.getDefaultValueForValueType(nextValueObjectType)
      }
    }
    return nextItemState
  }

  updateConfigState = (itemIndex, innerItemIndex, updateObj) => {
    const configStateCopy = [...this.state.configState]
    const prevItemState = configStateCopy[itemIndex][innerItemIndex]
    configStateCopy[itemIndex][innerItemIndex] = this.getNextItemState(prevItemState, updateObj)
    this.setState({ configState: configStateCopy })
  }

  resetConfigState = (itemIndex, innerItemIndex) => {
    const configStateCopy = [...this.state.configState]
    configStateCopy[itemIndex][innerItemIndex] = getDefaultConfigItemState()
    this.setState({ configState: configStateCopy })
  }

  updateConfigStateChainItem = (itemIndex, innerItemIndex, chainItemIdx, updateObj) => {
    const configStateCopy = [...this.state.configState]
    const innerItemStateCopy = {
      ...this.state.configState[itemIndex][innerItemIndex],
    }
    const prevItemState = innerItemStateCopy.chain[chainItemIdx]
    const nextState = this.getNextItemState(prevItemState, updateObj)
    innerItemStateCopy.chain[chainItemIdx] = nextState
    _.set(configStateCopy, [itemIndex, innerItemIndex], innerItemStateCopy)
    this.setState({ configState: configStateCopy })
  }

  addOuterConfigRow = () => {
    const configStateCopy = [...this.state.configState]
    configStateCopy.push([getDefaultConfigItemState()])
    this.setState({ configState: configStateCopy })
  }

  addConfigItem = itemIndex => {
    const configStateCopy = [...this.state.configState]
    configStateCopy[itemIndex].push(getDefaultConfigItemState())
    this.setState({ configState: configStateCopy })
  }

  removeConfigItem = (itemIndex, innerItemIndex) => {
    let configStateCopy = [...this.state.configState]
    configStateCopy[itemIndex] = _.filter(configStateCopy[itemIndex], (value, index) => index !== innerItemIndex)
    if (!configStateCopy[itemIndex].length && configStateCopy.length > 1) {
      configStateCopy = _.filter(configStateCopy, (value, index) => index !== itemIndex)
    }
    this.setState({ configState: configStateCopy })
  }

  hasCampaignOrPerks = () => {
    const { associatedCampaignsAndPerks, customAutotagEditState, customTierID } = this.props
    return (
      associatedCampaignsAndPerks.data.campaigns.length > 0 ||
      (customTierID in customAutotagEditState.perksByTagName && customAutotagEditState.perksByTagName[customTierID].length > 0)
    )
  }

  shouldShowDeactivateWarning = is_active => {
    const { isEditMode } = this.props
    return !is_active && isEditMode && this.hasCampaignOrPerks()
  }

  shouldShowDeleteWarning = () => {
    const { isEditMode } = this.props
    return isEditMode && this.hasCampaignOrPerks()
  }

  submitClickHandler = is_active => {
    if (this.props.saving || !this.props.customAutotagObjectsAreReady) {
      return
    }
    if (this.state.tagName === '') {
      this.setState({ isAutotagNameValid: false })
      return
    }
    const [isValid, errorMessage] = validateCustomAutotagConfigState(this.state.configState)
    if (!isValid) {
      this.setState({ isConfigStateValid: false })
      return
    }
    if (this.shouldShowDeactivateWarning(is_active)) {
      this.props.toggleDeactivateWarning()
      this.props.updateDeactivateFunction(this.confirmDeactivate)
      return
    }
    this.props.trySaveCustomAutotag(this.props.venue, this.state, this.props.customTierID)
  }

  deleteClickHandler = isActive => {
    if (this.shouldShowDeleteWarning()) {
      this.props.toggleDeactivateWarning()
      this.props.updateDeactivateFunction(this.confirmDelete)
      return
    }
    const { venue, tryDeleteAutotag } = this.props
    tryDeleteAutotag(venue, this.props.customTierID)
  }

  confirmDeactivate = () => {
    this.props.trySaveCustomAutotag(this.props.venue, this.state, this.props.customTierID)
  }

  confirmDelete = () => {
    const { venue, tryDeleteAutotag } = this.props
    tryDeleteAutotag(venue, this.props.customTierID)
    this.props.toggleDeactivateWarning()
  }

  render() {
    const {
      venue,
      saving,
      autotagConfig,
      isEditMode,
      customAutotagObjects,
      customAutotagObjectsAreReady,
      customTierID,
      canManageGlobalAutoTags,
    } = this.props
    const { name_display, is_global, updated, updated_by_user_name } = autotagConfig
    const { tagName, is_active, isAutotagNameValid } = this.state
    const lastUpdated =
      venue.locale === 'en_US'
        ? moment.utc(updated).local().format('MMM D, YYYY h:mmA')
        : moment.utc(updated).local().format('MMM D, YYYY HH:mm')

    const slideoutTitle = isEditMode ? 'EDIT AUTO-TAG' : 'CREATE AUTO-TAG'
    const customAutotagConfigTitle = name_display
    const buttonText = isEditMode ? 'Update' : 'Create'
    const hasNoChanges = _.isEqual(this.state, this.initialState)
    // when creating a new custom tag and the tier id does not exist yet
    const tierId = customTierID || NEW_CUSTOM_TIER_ID_PLACEHOLDER

    return (
      <AutotagSlideout title={<SlideoutTitle>{slideoutTitle}</SlideoutTitle>} maxWidth="800px">
        <Content>
          <DisplayArea id={this.viewID}>
            {isEditMode && (
              <Heading id="tagSlideoutHeader">
                <SubHead>
                  <StyledVmsIconSH>{is_global ? VmsIcons.SafariLine : VmsIcons.LocationLine}</StyledVmsIconSH>
                  <Title id="tag-title">{customAutotagConfigTitle}</Title>
                </SubHead>
                <SubHead id="tag-toggle" onClick={this.toggleTagActive} style={{ cursor: 'pointer' }}>
                  <StyledVmsIconSH
                    style={{
                      color: is_active ? Theme.color.teal : Theme.color.grey,
                    }}
                  >
                    {is_active ? VmsIcons.ToggleOn : VmsIcons.ToggleOff}
                  </StyledVmsIconSH>
                  <TextIndicator on={is_active} />
                </SubHead>
              </Heading>
            )}
            {isEditMode && (
              <LastUpdated>
                Last updated {lastUpdated} by {updated_by_user_name}
              </LastUpdated>
            )}
            <InnerContent flexStart>
              <InnerContentLabel>SETTINGS</InnerContentLabel>
              <ActiveLabelTextInput
                field="tagName"
                onChange={this.autotagNameChangeHandler}
                value={tagName}
                placeholderLabel="Auto-tag Name"
                isValid={isAutotagNameValid}
              />
            </InnerContent>
            {!isEditMode && (
              <InnerContent padding="0px 28px 5px 28px">
                <SmallInnerContentLabel>Auto-tag Type</SmallInnerContentLabel>
                <RadioList
                  field="scope"
                  onClick={this.changeFieldHandler}
                  selectedValue={this.state.scope}
                  customRadioListWrapper={css`
                    display: flex;
                    justify-content: flex-start;
                    width: 100%;
                  `}
                  customRadioWrapper={css`
                    height: auto;
                    margin-right: 80px;
                  `}
                  customRadioCheck={css`
                    background-color: #0abcc2;
                  `}
                  customRadioIcon={css`
                    :focus {
                      border: 2px solid #0abcc2;
                      background-color: #e0feff;
                    }
                  `}
                  options={
                    canManageGlobalAutoTags
                      ? [
                          {
                            value: RadioListValueTypes.LOCAL,
                            label: 'Local',
                            alwaysShowChild: true,
                            child: (
                              <ScopeDesc>
                                <ScopeDescItem>Viewable for this venue only</ScopeDescItem>
                                <ScopeDescItem>Applies to guest data for this venue</ScopeDescItem>
                              </ScopeDesc>
                            ),
                          },
                          {
                            value: RadioListValueTypes.GLOBAL,
                            label: 'Global',
                            alwaysShowChild: true,
                            child: (
                              <ScopeDesc>
                                <ScopeDescItem>Viewable on any venue</ScopeDescItem>
                                <ScopeDescItem>Applies to all guest data across all venues</ScopeDescItem>
                              </ScopeDesc>
                            ),
                          },
                        ]
                      : [
                          {
                            value: RadioListValueTypes.LOCAL,
                            label: 'Local',
                            alwaysShowChild: true,
                            child: (
                              <ScopeDesc>
                                <ScopeDescItem>Viewable for this venue only</ScopeDescItem>
                                <ScopeDescItem>Applies to guest data for this venue</ScopeDescItem>
                              </ScopeDesc>
                            ),
                          },
                        ]
                  }
                />
              </InnerContent>
            )}
            <VmsCheckboxWrapper>
              <VmsCheckbox
                checkboxId="isForMarketingSegmentation"
                field="isForMarketingSegmentation"
                onClick={this.changeCheckboxFieldHandler}
                isSelected={this.state.isForMarketingSegmentation}
                label="Use only for Marketing Segmentation"
                tooltipText="When selected, these tags will only be visible on the Client Detail page and the search Clients report for use in marketing campaigns."
                tooltipWidth="200px"
                selectedIcon={VmsIcons.CheckBoxSelected}
                unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
                tooltipIcon={VmsIcons.Info}
                checkedColor="#0ABCC2"
                tooltipColor="#6A6A6A"
                isDisabled={!!isEditMode}
              />
            </VmsCheckboxWrapper>
            <AutotagPermissions permission="isLoyaltyAndPerksEnabled">
              <Hr />
              <InnerContent flexStart>
                <InnerContentLabel>ADDITIONAL CONFIGURATION</InnerContentLabel>
                <InnerContentDescription>
                  Perks will display on the reservation slideout on both web and mobile when applicable.
                </InnerContentDescription>
                <CustomAutotagTierAdditionalOptions
                  autotagPerks={this.props.autotagPerks.perks}
                  changeFieldHandler={this.changeFieldHandler}
                  perkIds={tierId in this.state.perksByTagName ? this.state.perksByTagName[tierId] : []}
                  additionalNotes={tierId in this.state.notesByTagName ? this.state.notesByTagName[tierId] : []}
                  tagName={tierId}
                />
              </InnerContent>
            </AutotagPermissions>
            <Hr />
            <InnerContent flexStart>
              <InnerContentLabel>CONDITIONS</InnerContentLabel>
              {customAutotagObjectsAreReady && (
                <CustomAutotagConfigEdit
                  viewID={this.viewID}
                  configState={this.state.configState}
                  updateConfigState={this.updateConfigState}
                  updateConfigStateChainItem={this.updateConfigStateChainItem}
                  addOuterConfigRow={this.addOuterConfigRow}
                  addConfigItem={this.addConfigItem}
                  removeConfigItem={this.removeConfigItem}
                  customAutotagObjects={customAutotagObjects}
                  isGlobal={this.state.scope === RadioListValueTypes.GLOBAL}
                  isConfigStateValid={this.state.isConfigStateValid}
                  resetConfigState={this.resetConfigState}
                />
              )}
            </InnerContent>
          </DisplayArea>
          <ActionArea>
            <ActionsContainer>
              {!is_active && isEditMode && (
                <Delete id="sr-custom-autotag-delete" onClick={() => this.deleteClickHandler(is_active)}>
                  Delete
                </Delete>
              )}

              <CreateButton id="sr-custom-autotag-submit" onClick={() => this.submitClickHandler(is_active)} disabled={hasNoChanges}>
                {saving || !customAutotagObjectsAreReady ? <PulsatingDots /> : buttonText}
              </CreateButton>
            </ActionsContainer>
          </ActionArea>
        </Content>
      </AutotagSlideout>
    )
  }
}

const defaultConditionToValueMap = {
  RESERVATION_COUNT: ['NTIMES', 'GREATER_THAN_OR_EQUAL'],
  RESERVATION_DATE: ['ANY_DATE', 'EVER'],
}

const mapStateToProps = state => {
  const customAutotagObjects = selectCustomAutotagObjects(state)
  const clientTagsOptionsList = selectClientTagsOptionsList(state)
  const customAutotagObjectsAreReady = !_.isEmpty(customAutotagObjects.conditionsList) && !_.isEmpty(clientTagsOptionsList)
  const customAutotagEditState = selectCustomAutotagEditState(state)
  const isEditMode = !_.isEmpty(customAutotagEditState.tierConfig)
  return {
    autotagPerks: state.autotagPerks,
    venue: state.appState.venue,
    saving: state.autotags.editor.saving,
    locale: state.appState.venue.locale,
    defaultValueMap: selectCustomAutotagDefaultValueMap(state),
    associatedCampaignsAndPerks: state.autotags.editor.associatedCampaignsAndPerks,
    canManageGlobalAutoTags: state.appState.userDomain.can_manage_global_auto_tags,
    defaultConditionToValueMap,
    customAutotagObjects,
    customAutotagObjectsAreReady,
    customAutotagEditState,
    isEditMode,
    autotagConfig: state.autotags.editor.tag || {},
    customTierID: _.get(customAutotagEditState, ['tierConfig', 'custom_tier_id'], null),
  }
}

const mapDispatchToProps = {
  toggleCustomAutotagSlideout,
  trySaveCustomAutotag,
  tryDeleteAutotag,
  toggleDeactivateWarning,
  updateDeactivateFunction,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomAutotagEditor)
