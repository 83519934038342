import type { TableItem } from '@sevenrooms/core/api/index'
import type { SeatingAreaToTables } from '@sevenrooms/core/domain/index'
import { createRequiredContext } from '@sevenrooms/core/ui-kit/hooks'
import type { Availabilities } from './CreateExperienceFromTemplateForm.zod'

export interface ExperienceFromTemplateContext {
  currencyCode: string
  shiftSeatingAreas: { id: string; name: string; seatingAreas: SeatingAreaToTables[]; tables: TableItem[] }[]
  showSeatingAreasSelect: boolean
  setShowSeatingAreasSelect: (showSeatingAreasSelect: boolean) => void
  setShowNextButton: (showNextButton: boolean) => void
  setFlowCompleted: (flowCompleted: boolean) => void
  selectedAvailabilityOption: Availabilities | undefined
  setSelectedAvailabilityOption: (option: Availabilities | undefined) => void
  isAccessRuleCustomizationInitialPart: boolean
  setIsAccessRuleCustomizationInitialPart: (isInitial: boolean) => void
  setFlowStep: (step: number | undefined) => void
  setUseSaveButton: (useSaveButton: boolean) => void
}

export const [ExperienceFromTemplateContextProvider, useExperienceFromTemplateContext] =
  createRequiredContext<ExperienceFromTemplateContext>()
