import _ from 'lodash'
import { useLocales } from '@sevenrooms/core/locales'
import { campaignCenterMessages, Tag } from '@sevenrooms/marketing'
import { Box, DataGrid, type GridColDef, type GridPaginationModel, Stack, Typography } from '@sevenrooms/react-components'
import type { EmailCampaignRecipientDetailClient, EmailCampaignRecipientTag } from '../../api'

export interface LoyaltyProgramClientListProps {
  isLoading: boolean
  rowCount: number
  data: EmailCampaignRecipientDetailClient[]
  paginationModel: GridPaginationModel
  onPaginationModelChange: (paginationModel: GridPaginationModel) => void
}

export function CampaignPerformanceClientList({
  isLoading,
  rowCount,
  data,
  paginationModel,
  onPaginationModelChange,
}: LoyaltyProgramClientListProps) {
  const { formatMessage } = useLocales()

  const columns: GridColDef<EmailCampaignRecipientDetailClient>[] = [
    {
      field: 'name',
      headerName: formatMessage(campaignCenterMessages.recipient),
      flex: 1,
      valueGetter: (_value, row: EmailCampaignRecipientDetailClient) => `${row?.firstName} ${row?.lastName}`,
      renderCell: data => {
        const name = data?.value ?? ''
        const email = data?.row?.email ?? ''

        return (
          <Stack gap="2px" paddingY="12px">
            <Box>{name}</Box>
            <Typography color="#6B6B6B">{email}</Typography>
          </Stack>
        )
      },
    },
    {
      field: 'totalVisits',
      headerName: formatMessage(campaignCenterMessages.visits),
      width: 115,
      renderCell: data => <Box paddingY="12px">{_.isNil(data.value) ? '-' : data.value}</Box>,
    },
    {
      field: 'totalSpend',
      headerName: formatMessage(campaignCenterMessages.spend),
      width: 115,
      renderCell: data => <Box paddingY="12px">{_.isNil(data.value) ? '-' : <>{data.value.toLocaleString()}</>}</Box>,
    },
    {
      field: 'clientTags',
      headerName: formatMessage(campaignCenterMessages.tags),
      flex: 1,
      sortable: false,
      renderCell: data => {
        const tags = data.value as EmailCampaignRecipientTag[]

        if (_.isEmpty(tags)) {
          return <>-</>
        }

        return (
          <Stack flexDirection="row" flexWrap="wrap" gap="4px" paddingY="12px">
            {_.compact(
              tags.map(tag => {
                if (tag) {
                  const isLoyalty = _.startsWith(tag.tagGroupName, 'LOYALTY')
                  return (
                    <Tag key={tag.tagHash} isLoyalty={isLoyalty} isAuto={tag.isAutotag} color={tag.tagColor} title={tag.tagNameDisplay} />
                  )
                }

                return null
              })
            )}
          </Stack>
        )
      },
    },
  ]

  return (
    <Box paddingY="24px">
      <Stack bgcolor="#FFFFFF">
        <DataGrid
          pageSizeOptions={[10, 30, 50]}
          paginationModel={paginationModel}
          rowCount={rowCount}
          onPaginationModelChange={onPaginationModelChange}
          sortingMode="server"
          paginationMode="server"
          columns={columns}
          rows={data ?? []}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 66}
          disableColumnFilter
          loading={isLoading}
          disableRowSelectionOnClick
          disableColumnSorting
        />
      </Stack>
    </Box>
  )
}
