import { Redirect, Route, Switch } from '@sevenrooms/core/navigation'
import { SettingsPage, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
// eslint-disable-next-line import/no-relative-packages
import { ErrorNotFound } from '../../../../../application/site/static/app/manager/pages/src/default'
import { useNavigationItems } from '../../hooks'

export function Marketing() {
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()

  const sideNavigationItems = useNavigationItems()
  if (!venue || !venueSettings) {
    return null
  }

  return (
    <SettingsPage showSideNavigation sideNavigationItems={sideNavigationItems}>
      <Switch>
        {sideNavigationItems.map(categoryItem =>
          categoryItem.items.map(pageItem =>
            pageItem.routeConfigs?.map(routeConfig => {
              let redirect = ''
              if (categoryItem.allowed === false) {
                redirect = categoryItem.redirect || '/'
              } else if (pageItem.allowed === false && pageItem.redirect) {
                redirect = pageItem.redirect
              }

              return (
                <Route key={routeConfig.path} path={routeConfig.path} component={routeConfig.component}>
                  {redirect && <Redirect to={redirect} />}
                </Route>
              )
            })
          )
        )}
        <Route path="*" component={ErrorNotFound} />
      </Switch>
    </SettingsPage>
  )
}
