import { useMemo, useCallback } from 'react'
import type { OneTimeEmailCampaignType } from '@sevenrooms/core/api'
import { MarketingCampaignTypeEnum, type VenueProfile } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Surface, useDestination, useNavigation } from '@sevenrooms/core/navigation'
import { Button, Form } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import {
  Audience,
  emailBuilderMessages,
  generateEmailCampaignPreviewTemplate,
  SendTestEmailModal,
  getPlainTextFromHtml,
  EmailDetails,
  EmailContentFromScratch,
  type EmailDetailsFormField,
  type AudienceFormField,
  type EmailContentFormField,
} from '@sevenrooms/marketing'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import {
  getOneTimeEmailBuilderFormDefaultValues,
  useOneTimeEmailCampaignForm,
} from '../OneTimeEmailCampaignForm/OneTimeEmailCampaignForm.zod'
import { OneTimeSendingSchedule, type OneTimeSendingScheduleFormField } from '../OneTimeSendingSchedule'

export interface OneTimeEmailCampaignViewerFormProps {
  emailCampaign: OneTimeEmailCampaignType
  venueProfile: VenueProfile
}

export function OneTimeEmailCampaignViewerForm({ emailCampaign, venueProfile }: OneTimeEmailCampaignViewerFormProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { params: editParams } = useDestination(routes.manager2.marketing.oneTimeEmailCenter.viewOneTimeEmail)
  const { campaignId } = editParams

  const { venue, venueId } = useVenueContext()

  const defaultValues = useMemo(() => getOneTimeEmailBuilderFormDefaultValues(emailCampaign ?? undefined), [emailCampaign])
  const form = useForm(useOneTimeEmailCampaignForm(), {
    defaultValues,
  })
  const { field, getValues } = form

  const onCampaignDuplicateClick = useCallback(
    (campaignId: string) => {
      if (venue) {
        nav.push(routes.manager2.marketing.oneTimeEmailCenter.createOneTimeEmail, {
          params: { venueKey: venue.urlKey },
          query: { campaignToDuplicateId: campaignId, parentTemplateId: '' },
        })
      }
    },
    [nav, venue]
  )

  if (!venue) {
    return null
  }

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(emailBuilderMessages.viewEmailCampaign)} />
      <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
        <SettingsPageContent
          title={emailCampaign ? `${emailCampaign.campaignName}` : ''}
          actions={
            <HStack spacing="s">
              <Button
                variant="tertiary"
                data-test="one-time-email-builder-cancel-button"
                onClick={() => {
                  window.location.assign(
                    nav.href(routes.manager2.marketing.oneTimeEmailCenter, { params: { venueKey: venue.urlKey } }).split('?')[0] || ''
                  )
                }}
              >
                {formatMessage(commonMessages.cancel)}
              </Button>
              {emailCampaign.usingTopolEditor && (
                <Button
                  type="submit"
                  data-test="one-time-email-builder-duplicate-button"
                  onClick={() => onCampaignDuplicateClick(campaignId)}
                >
                  {formatMessage(commonMessages.duplicate)}
                </Button>
              )}
            </HStack>
          }
        >
          <Box p="lm" width="100%">
            <VStack spacing="lm">
              <EmailDetails
                disabled
                autoFocus
                campaign={emailCampaign}
                field={field as unknown as EmailDetailsFormField}
                data-test="one-time-email-builder-details-section"
              />
              <Audience
                campaignContent={emailCampaign}
                venueProfile={venueProfile}
                field={field as unknown as AudienceFormField}
                venue={venue}
                isOneTimeCampaign
                disabled
                messages={emailBuilderMessages}
                data-test="one-time-email-builder-audience-section"
              />
              <OneTimeSendingSchedule
                field={field as unknown as OneTimeSendingScheduleFormField}
                disabled
                data-test="one-time-email-builder-sending-schedule-section"
              />
              <EmailContentFromScratch
                disabled
                fields={field as unknown as EmailContentFormField}
                data-test="one-time-email-builder-content-section"
                imageUpload
                venueProfile={venueProfile}
                shouldUseTopol={false}
                template={null}
                groupVenues={[]}
              />
            </VStack>
          </Box>
        </SettingsPageContent>
      </Form>
      <Surface destination={routes.manager2.marketing.emailCenter.emails.emailBuilder.sendTestEmailModal}>
        <Window>
          <SendTestEmailModal
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.emailCenter.emails.emailBuilder.sendTestEmailModal, {
              params: { venueKey: venue.urlKey },
            })}
            subjectLine={getPlainTextFromHtml(getValues().subjectLine.value ?? '')}
            senderAddress={getPlainTextFromHtml(getValues().emailSender.value ?? '')}
            replyToAddress={getValues().replyToAddress}
            previewText={getValues().emailPreview.value}
            template={generateEmailCampaignPreviewTemplate('', getValues().emailBodySections[0]?.value ?? '', '', '', '')}
            emailBody={getValues().emailBodySections[0]?.value ?? ''}
            signoff=""
            emailGreeting=""
            footer=""
            signature=""
            venueId={venueId}
            campaignType={MarketingCampaignTypeEnum.ONE_TIME}
            withPreview={false}
          />
        </Window>
      </Surface>
    </>
  )
}
