import { useCallback } from 'react'
import { MarketingCampaignOneTimeSendTypeEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useHistory } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, type ModalHeaderProps, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { oneTimeSMSCenterMessages } from '../../locales'

export interface ConfirmSendSMSModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onCancel: () => void
  onConfirm: () => void
  oneTimeSendType: MarketingCampaignOneTimeSendTypeEnum
  disabled: boolean
}

export function ConfirmSendSMSModal({ closeHref, onCancel, onConfirm, oneTimeSendType, disabled }: ConfirmSendSMSModalProps) {
  const { formatMessage } = useLocales()
  const history = useHistory()
  const isScheduledCampaign = oneTimeSendType === MarketingCampaignOneTimeSendTypeEnum.SCHEDULED

  const buttonText = isScheduledCampaign
    ? formatMessage(oneTimeSMSCenterMessages.confirmScheduleButtonText)
    : formatMessage(oneTimeSMSCenterMessages.confirmSendButtonText)

  const onCancelClick = useCallback(() => {
    onCancel()
    history.push(closeHref)
  }, [closeHref, history, onCancel])

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle title={formatMessage(oneTimeSMSCenterMessages.confirmSendSMSTitle)} />
      </ModalHeader>
      <ModalBody>
        <Text>{formatMessage(oneTimeSMSCenterMessages.confirmSendSMSText)}</Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button onClick={onCancelClick} variant="secondary" data-test="send-email-modal-cancel-button">
            {formatMessage(oneTimeSMSCenterMessages.confirmSMSCancelButtonText)}
          </Button>

          <Button data-test="send-email-modal-confirm-button" onClick={onConfirm} disabled={disabled}>
            {buttonText}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
