// @flow
import * as React from 'react'
import { type CancellationDeadline, type PDRCancellationDeadlineFromOption, PDRDeadlineFromEnum } from '@sevenrooms/core/domain'
import { type Field, useFieldArray } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, FormNumberInput, FormPercentageInput, FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { spacesMessages } from '../../spaces.locales'
import { pdrDeadlineTimeUnitOptions } from './selectOptions'
import { useCancellationDeadlineMatrixForm } from './Space.zod'

interface Props {
  field: Field<CancellationDeadline[]>
}

export function CancellationDeadlines({ field }: Props) {
  const { formatMessage } = useLocales()
  const { fields, append, remove } = useFieldArray(field, '' as never)
  const defaultValues = useCancellationDeadlineMatrixForm().parse({})

  const spacesMessagesTyped: Record<PDRDeadlineFromEnum, { id: string; defaultMessage: string }> = spacesMessages

  const cancellationDeadlineFromOptions = Object.values(PDRDeadlineFromEnum).map(value => ({
    id: value as PDRCancellationDeadlineFromOption,
    label: formatMessage(spacesMessagesTyped[value]),
  }))

  return (
    <VStack spacing="lm">
      <Text fontWeight="bold">Cancellation deadlines</Text>
      {fields?.map((item, idx) => (
        <HStack key={item.id} spacing="lm">
          <VStack flex="2" justifyContent="flex-end" maxWidth="50px">
            <FormNumberInput field={field.prop(`${idx}.number`) as Field<number | null>} />
          </VStack>
          <VStack flex="4" justifyContent="flex-end">
            <FormSelect field={field.prop(`${idx}.timeUnit`)} options={pdrDeadlineTimeUnitOptions} />
          </VStack>
          <VStack flex="1" justifyContent="center">
            <Icon name="VMSWeb-chevron-right" />
          </VStack>
          <VStack flex="2" justifyContent="flex-end" maxWidth="80px">
            <Label primary="Refund" />
            <FormPercentageInput field={field.prop(`${idx}.refund`) as Field<number | null>} />
          </VStack>
          <VStack flex="4" justifyContent="flex-end">
            <Label primary="From" />
            <FormSelect field={field.prop(`${idx}.refundFrom`)} options={cancellationDeadlineFromOptions} />
          </VStack>
          <VStack justifyContent="center" flex="1">
            <Button data-test="trash-button" onClick={() => remove(idx)} size="s" variant="tertiary">
              <Icon size="lg" name="VMSWeb-trash" />
            </Button>
          </VStack>
        </HStack>
      ))}
      <Box>
        <Button data-test="add-time-slot-button" onClick={() => append(defaultValues)} icon="VMSWeb-add" variant="secondary" size="s">
          {formatMessage(spacesMessages.cancellationDeadlineAdd)}
        </Button>
      </Box>
    </VStack>
  )
}
