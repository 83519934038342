import { useLocales } from '@sevenrooms/core/locales'
import { Redirect, Route, Switch } from '@sevenrooms/core/navigation'
import { SettingsPageContent, SettingsPageMeta, useUserContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { routes } from '@sevenrooms/routes'
import smsMarketingPreview from '../assets/sms-marketing-preview.png'
import { marketingUpsellMessages, mgrAcctUpgradesMessages } from '../locales'
import { AvailableUpgradePromoPage } from './AvailableUpgradePromoPage'

export function SMSMarketingUpgrade() {
  const { formatMessage } = useLocales()
  const { venueName, venueSquid } = useAppContext()
  const user = useUserContext()
  const title = formatMessage(mgrAcctUpgradesMessages.textMarketing)
  const route = routes.manager2.marketing.upgrades.textMarketing.path

  const contactRequestParams = new URLSearchParams({
    utm_source: 'PLG',
    channel: 'textmarketing',
    firstname: user?.first_name ?? '',
    lastname: user?.last_name ?? '',
    email: user?.email ?? '',
    squid: venueSquid ?? '',
  })

  const contactRequestUrl = `https://sevenrooms.com/customer-text-marketing-request-product?${contactRequestParams.toString()}`

  return (
    <>
      <SettingsPageMeta venue={venueName} title={title} />
      <SettingsPageContent headerWidth="calc(100% - 274px)">
        <Switch>
          <Route path={route}>
            <AvailableUpgradePromoPage
              title={title}
              leadDescription={formatMessage(marketingUpsellMessages.smsMarketingLead)}
              numericalStats={[
                { number: 0.98, formatType: 'percent', description: formatMessage(marketingUpsellMessages.smsMarketingStat0) },
                {
                  number: 1.69,
                  formatType: 'currency',
                  currency: 'USD',
                  description: formatMessage(marketingUpsellMessages.smsMarketingStat1),
                  footnotes: [formatMessage(marketingUpsellMessages.smsMarketingStat1Footnote0)],
                },
                {
                  number: 51,
                  formatType: 'multiplier',
                  description: formatMessage(marketingUpsellMessages.smsMarketingStat2),
                  footnotes: [formatMessage(marketingUpsellMessages.smsMarketingStat2Footnote0)],
                },
              ]}
              bullets={formatMessage(marketingUpsellMessages.smsMarketingBullets, {
                li: (val: string) => `${val}\n`,
              })
                .split('\n')
                .filter((val: string) => !!val)}
              includeHeaderPaddingBottom={false}
              imageHref={smsMarketingPreview}
              imageAlt={formatMessage(marketingUpsellMessages.smsMarketingImageAlt)}
              ctaDestination={contactRequestUrl}
              useNewFeatureFlag
              analyticsKeySuffix="sms-mkt"
            />
          </Route>
          <Redirect to={route} />
        </Switch>
      </SettingsPageContent>
    </>
  )
}
