import HourglassTopIcon from '@mui/icons-material/HourglassTopOutlined'
import { useMemo } from 'react'
import type { CampaignActivityItem } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { DateTime } from '@sevenrooms/core/timepiece'
import { BaseSection, BorderedBox, Flex, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { campaignCenterMessages } from '@sevenrooms/marketing'
import { getPercentageOfTotal } from '@sevenrooms/marketing/utils'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { IconButton } from '@sevenrooms/react-components/components/IconButton'
import { Card } from './Card'

export function CampaignAnalysis({
  activity,
  sendTime,
  isEmpty,
}: {
  activity: CampaignActivityItem
  sendTime?: DateTime
  isEmpty: boolean
}) {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()

  const cards = useMemo(
    () => [
      {
        heading: formatMessage(campaignCenterMessages.campaignPerformanceRecipientEngagement),
        items: [
          {
            value: getPercentageOfTotal(activity.opens, activity.recipients),
            title: formatMessage(campaignCenterMessages.campaignPerformanceOpens),
            withPercent: true,
          },
          {
            value: getPercentageOfTotal(activity.clicks, activity.recipients),
            title: formatMessage(campaignCenterMessages.campaignPerformanceClickToOpenRate),
            withPercent: true,
          },
          {
            value: getPercentageOfTotal(activity.reservations, activity.opens),
            title: formatMessage(campaignCenterMessages.campaignPerformanceConversion),
            withPercent: true,
          },
        ],
      },
      {
        heading: formatMessage(campaignCenterMessages.campaignPerformanceConversion),
        items: [
          {
            value: `${activity.reservations} (${activity.covers})`,
            title: `${formatMessage(campaignCenterMessages.campaignPerformanceReservations)} (${formatMessage(
              campaignCenterMessages.campaignPerformanceCovers
            )})`,
          },
          ...(venue.isSevenRoomsOrderingEnabled
            ? [{ value: activity.orders, title: formatMessage(campaignCenterMessages.campaignPerformanceOnlineOrders) }]
            : []),
          {
            value: `${venue.currencySymbol} ${Math.round(activity.totalRevenue)}`,
            title: formatMessage(campaignCenterMessages.campaignPerformanceTotalRevenue),
          },
        ],
      },
      {
        heading: formatMessage(campaignCenterMessages.campaignPerformanceDelivery),
        items: [
          { value: activity.recipients, title: formatMessage(campaignCenterMessages.campaignPerformanceRecipients) },
          {
            value: getPercentageOfTotal(activity.unsubscribed, activity.recipients),
            title: formatMessage(campaignCenterMessages.campaignPerformanceUnsubscribeRate),
            withPercent: true,
          },
          {
            value: getPercentageOfTotal(activity.bounces, activity.recipients),
            title: formatMessage(campaignCenterMessages.campaignPerformanceBounceRate),
            withPercent: true,
          },
          {
            value: getPercentageOfTotal(activity.complaints, activity.recipients),
            title: formatMessage(campaignCenterMessages.campaignPerformanceComplaintRate),
            withPercent: true,
          },
        ],
      },
    ],
    [formatMessage, activity, venue.currencySymbol, venue.isSevenRoomsOrderingEnabled]
  )
  const toDate = DateTime.from(new Date().toISOString()).formatNYearNMonthNDay()
  const fromDate = sendTime?.formatNYearNMonthNDay() ?? ''

  return (
    <BaseSection
      title={formatMessage(campaignCenterMessages.campaignAnalysisTitle)}
      description={formatMessage(campaignCenterMessages.campaignDateRangeDescription, { fromDate, toDate })}
    >
      <BorderedBox m="lm" position="relative">
        {cards.map(({ heading, items }) => (
          <Card heading={heading} items={items} key={heading} isEmpty={isEmpty} />
        ))}
        {isEmpty && (
          <Flex position="absolute" top="none" justifyContent="center" alignItems="center" width="100%" height="100%" zIndex="overlay">
            <HStack
              mb="lm"
              backgroundColor="primaryBackground"
              p="m s"
              alignItems="center"
              width="385px"
              borderRadius="s"
              boxShadow="secondary"
            >
              <IconButton data-test="empty-state-icon">
                <HourglassTopIcon />
              </IconButton>
              <VStack spacing="s">
                <Text fontWeight={700} color="darkFont">
                  {formatMessage(campaignCenterMessages.emptyStateTitle)}
                </Text>
                <Text color="inactive">{formatMessage(campaignCenterMessages.emptyStateDescription)}</Text>
              </VStack>
            </HStack>
          </Flex>
        )}
      </BorderedBox>
    </BaseSection>
  )
}
