import _ from 'lodash'
import { useMemo } from 'react'
import type { OneTimeSMSCampaignTemplate, OneTimeSMSCampaignType } from '@sevenrooms/core/api'
import { MarketingCampaignOneTimeSendTypeEnum, type GenericTagGroup, OneTimeSMSCampaignStatusEnum } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import {
  campaignBuilderMessages,
  smsBuilderMessages,
  useAudienceV2Form,
  getAudienceV2DefaultValues,
  htmlToSMS,
} from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { getOneTimeSendingScheduleDefaultValues, useOneTimeSendingScheduleForm } from '@sevenrooms/mgr-marketing-one-time-email-center'

export type OneTimeSMSCampaignFormData = ZodSchema<typeof useOneTimeSMSCampaignForm>

const CHAR_LIMIT = 1500
const LINK_ESTIMATED_LENGTH = 30
const LINK_REPLACEMENT_PLACEHOLDER = new Array(LINK_ESTIMATED_LENGTH).join('a')
const LINK_REGEX = /<a\s.+?<\/a>/gi

export function useOneTimeSMSCampaignForm() {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const audience = useAudienceV2Form()
  const sendingSchedule = useOneTimeSendingScheduleForm()

  return useMemo(
    () =>
      z
        .object({
          campaignName: z.string().trim().min(1),
          campaignState: z.nativeEnum(OneTimeSMSCampaignStatusEnum),
          messageBody: z
            .string()
            .trim()
            .min(1)
            .refine(message => htmlToSMS(message).replace(LINK_REGEX, LINK_REPLACEMENT_PLACEHOLDER).length <= CHAR_LIMIT, {
              message: formatMessage(smsBuilderMessages.smsMessageTooLongError, { limit: CHAR_LIMIT }),
            })
            .optional(),
          messageMedia: z.array(z.string().trim().url()).min(1).optional().nullable(),
          stagedMedia: z
            .object({ rawUrl: z.string().min(1), name: z.string().min(1) })
            .or(z.instanceof(File))
            .optional()
            .nullable(),
        })
        .merge(audience)
        .merge(sendingSchedule)
        .superRefine(
          (
            {
              campaignState,
              messageMedia,
              messageBody,
              useRecipientAutotags,
              recipientAutotags,
              excludedAutotags,
              scheduleType,
              scheduledDay,
              scheduledTime,
            },
            ctx
          ) => {
            if (!venue.isMMSEnabled) {
              if (messageMedia) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: formatMessage(smsBuilderMessages.smsMessageHasMediaInInvalidRegion),
                  path: ['messageMedia'],
                })
              }
              if (!messageBody) {
                ctx.addIssue({
                  code: z.ZodIssueCode.invalid_type,
                  expected: 'string',
                  received: 'undefined',
                  path: ['messageBody'],
                })
              }
            } else if (!messageMedia && !messageBody) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(smsBuilderMessages.smsMessageHasNoContentError),
                path: ['messageMedia'],
              })
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(smsBuilderMessages.smsMessageHasNoContentError),
                path: ['messageBody'],
              })
            }

            if (useRecipientAutotags) {
              if (recipientAutotags.length < 1) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: formatMessage(campaignBuilderMessages.recipientTagsRequiredError),
                  path: [`recipientAutotags`],
                  fatal: true,
                })
              }
            }

            if (campaignState === OneTimeSMSCampaignStatusEnum.ACTIVE) {
              if (!scheduleType) {
                ctx.addIssue({
                  code: z.ZodIssueCode.invalid_type,
                  expected: 'string',
                  received: 'undefined',
                  path: ['scheduleType'],
                })
              }
              if (recipientAutotags?.some(tag => tag.disabled)) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: ` `,
                  path: ['recipientAutotags'],
                })
              }
              if (excludedAutotags?.some(tag => tag.disabled)) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: ` `,
                  path: ['excludedAutotags'],
                })
              }

              if (scheduleType === MarketingCampaignOneTimeSendTypeEnum.SCHEDULED) {
                if (!scheduledDay) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.invalid_date,
                    path: ['scheduledDay'],
                  })
                }
                if (!scheduledTime) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.invalid_type,
                    expected: 'string',
                    received: 'undefined',
                    path: ['scheduledTime'],
                  })
                }
                if (scheduledTime && scheduledDay) {
                  const scheduledDate = new Date(_.isDate(scheduledDay) ? scheduledDay : `${scheduledDay}T00:00:00`)
                  if (scheduledTime) {
                    const time = TimeOnly.from(scheduledTime).getInfo()

                    scheduledDate.setHours(time.hours)
                    scheduledDate.setMinutes(time.minutes)
                    scheduledDate.setSeconds(time.seconds)
                  }
                  const today = new Date(
                    new Date().toLocaleString('en-US', {
                      timeZone: venue.timezone,
                    })
                  )
                  if (today.getTime() > scheduledDate.getTime()) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: formatMessage(campaignBuilderMessages.timeInThePastError),
                      path: ['scheduledTime'],
                    })
                  }
                }
              }
            }
          }
        ),
    [formatMessage, venue.timezone, venue.isMMSEnabled, audience, sendingSchedule]
  )
}

export const getOneTimeSMSBuilderFormDefaultValues = (
  source?: OneTimeSMSCampaignType | OneTimeSMSCampaignTemplate,
  clientTagGroups?: GenericTagGroup[]
): OneTimeSMSCampaignFormData => ({
  campaignName: source?.campaignName ?? '',
  campaignState: source?.status || OneTimeSMSCampaignStatusEnum.ACTIVE,
  messageBody: source?.messageBody ? source.messageBody.replace(/[\n\r]/g, '<br />') : undefined,
  messageMedia: source?.messageMedia?.length ? source.messageMedia : undefined,
  stagedMedia: source?.messageMedia?.length && source.messageMedia[0] ? { rawUrl: source.messageMedia[0], name: ' ' } : null,
  ...getOneTimeSendingScheduleDefaultValues(source),
  ...getAudienceV2DefaultValues(source, clientTagGroups),
})
