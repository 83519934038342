import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  nextButtonText: {
    id: 'stepperFlow.nextButtonText',
    defaultMessage: 'Next',
  },
  backButtonText: {
    id: 'stepperFlow.backButtonText',
    defaultMessage: 'Back',
  },
  finishButtonText: {
    id: 'stepperFlow.finishButtonText',
    defaultMessage: 'Finish',
  },
  cancelButtonText: {
    id: 'stepperFlow.cancelButtonText',
    defaultMessage: 'Cancel',
  },
  saveButtonText: {
    id: 'stepperFlow.saveButtonText',
    defaultMessage: 'Save Changes',
  },
  backToEditingButtonText: {
    id: 'stepperFlow.backToEditingButtonText',
    defaultMessage: 'Back to Editing',
  },
  exitWithoutSavingButtonText: {
    id: 'stepperFlow.exitWithoutSavingButtonText',
    defaultMessage: 'Exit Without Saving',
  },
  cancelModalHeader: {
    id: 'stepperFlow.cancelModalHeader',
    defaultMessage: 'Are you sure?',
  },
  cancelModalDescription: {
    id: 'stepperFlow.cancelModalDescription',
    defaultMessage: 'Canceling will result in the loss of all your inputs.',
  },
})
