import React from 'react'
import type { Experience } from '@sevenrooms/core/domain'
import type { UseForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { IconButton } from '@sevenrooms/core/ui-kit/form'
import { PreviewImage, Flex, VStack, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { Button } from '@sevenrooms/react-components/components/Button'
import { routes } from '@sevenrooms/routes'
import FinalPageImage from './assets/images/final-page.svg'
import { messages } from './locales/experienceStepperFlow.locales'
import type { CreateExperienceFromTemplateFormData } from './CreateExperienceFromTemplateForm.zod'

export function FinalPage({
  venueUrlKey,
  experience,
  form,
}: {
  venueUrlKey: string
  experience: Experience
  form: UseForm<z.ZodType<CreateExperienceFromTemplateFormData>>
}) {
  const { formatMessage } = useLocales()
  const { getValues } = form
  const values = getValues()
  const nav = useNavigation()

  const experienceLink = `${window.location.origin}/experiences/${venueUrlKey}/${experience.landingUrlKey}`
  const copyLink = () => navigator.clipboard.writeText(experienceLink || '')
  const onClose = () => window.location.assign(nav.href(routes.manager2.marketing.offers, { params: { venueKey: venueUrlKey } }))

  return (
    <>
      <Flex pr="m" pb="xxl" justifyContent="flex-end">
        <IconButton
          borderType="round"
          onClick={onClose}
          iconBackgroundColor="iconHover"
          iconBorderColor="iconHover"
          size="s"
          icon="VMSWeb-close"
          data-test="template-navigate-previous-btn"
        />
      </Flex>
      <VStack pt="lm" pb="lm" spacing="l" width="496px" alignItems="center" justifySelf="center" data-test="final-page-content">
        <PreviewImage
          src={values.heroImage ? values.heroImage?.preview || values.heroImage.rawUrl : FinalPageImage}
          maxWidth="496px"
          alt="icon"
          data-test="final-page-image"
          borderRadius="s"
        />
        <VStack spacing="s">
          <Text textStyle="h1" fontWeight="bold" textAlign="center">
            {formatMessage(messages.finalPageTitle, {
              offerName: (
                <Text textStyle="h1" fontWeight="bold">
                  "{values?.name}"
                </Text>
              ),
            })}
          </Text>
          <Text textStyle="body1" textAlign="center">
            {formatMessage(messages.finalPageDescription, { offerName: values?.name })}
          </Text>
        </VStack>
        <HStack width="496px" backgroundColor="secondaryBackground" alignItems="center" pr="s" borderColor="borders" borderRadius="s">
          <Input value={experienceLink} fullWidth disabled border="0" />
          <IconButton
            iconBorderColor="secondaryBackground"
            onClick={copyLink}
            iconBackgroundColor="secondaryBackground"
            size="s"
            icon="VMSWeb-copy"
            data-test="copy-experience-link-btn"
          />
        </HStack>
        <VStack spacing="s">
          <Button
            data-test="view-offer-landing-page-btn"
            variant="contained"
            size="medium"
            sx={{ width: '496px', boxShadow: 'none' }}
            onClick={() =>
              window.location.assign(
                nav.href(routes.manager2.marketing.offers.editExperience, {
                  params: { venueKey: venueUrlKey, experienceId: experience.id },
                })
              )
            }
          >
            {formatMessage(messages.viewOfferLandingPageButtonText)}
          </Button>
          <Button data-test="view-offers-btn" variant="outlined" size="medium" sx={{ width: '496px' }} onClick={onClose}>
            {formatMessage(messages.viewOffersButtonText)}
          </Button>
        </VStack>
      </VStack>
    </>
  )
}
