import FroalaEditor from 'froala-editor'
import { useCallback, useEffect, useState } from 'react'
// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components' // to be fixed in MA-857
import type { VenueProfile, ExperiencesData } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { Window, Box, Tooltip } from '@sevenrooms/core/ui-kit/layout'
import { plusBoxMultiplePath, defaultConfig, FormEditor } from '@sevenrooms/core/ui-kit/optional'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { routes } from '@sevenrooms/routes'
import { campaignBuilderMessages, smsBuilderMessages } from '../../locales'
import { htmlToSMS } from '../../utils'
import { Offer, NoOffer } from '../Modals'
import type { SMSEditorDestinations } from './types'

interface SMSEditorProps {
  disabled?: boolean
  field: Field<string | null | undefined>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: any
  venueProfile: VenueProfile | undefined
  experiencesData?: ExperiencesData
  isDirty?: boolean
  setGoToOffers?: Function
  venue: Venue | undefined
  'data-test': string
  destination: SMSEditorDestinations
}
const A_TAG_DETECT_REGEX = new RegExp('<a .+?>.+?</a>', 'ig')
const URL_DETECT_REGEX = /(https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/i

export function SMSEditor({
  disabled,
  field,
  config,
  venueProfile,
  experiencesData,
  isDirty,
  setGoToOffers,
  venue,
  'data-test': dataTest,
  destination,
}: SMSEditorProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueSettings } = useAppContext()
  const [editor, setEditor] = useState<FroalaEditor>()
  const [isUntrackedLinkTooltipShown, setIsUntrackedLinkTooltipShown] = useState<boolean>(false)

  const doLinkInsertion = useCallback(
    (link: { text: string; link: string }) => {
      if (editor) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        editor.link.insert(link.link, link.text)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        editor.selection.setAfter(editor.selection.endElement())
      }
    },
    [editor]
  )

  useEffect(() => {
    if (editor) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      editor.events.on('link.beforeInsert', (link: string, text?: string) => {
        if (!text) {
          doLinkInsertion({ link, text: '<link>' })
          return false
        }
        return true
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      editor.events.on('paste.beforeCleanup', (content: string) => htmlToSMS(content).replace(/\n/gi, '<br />'))
    }
  }, [doLinkInsertion, editor])

  const getEmbedOptions = () => {
    const result: { [k: string]: string } = {
      reservation: formatMessage(campaignBuilderMessages.reservation),
    }

    if (venue) {
      if (venueProfile?.menuLink) {
        result.menu = formatMessage(campaignBuilderMessages.menu)
      }
      result.offer = formatMessage(campaignBuilderMessages.offer)
    }

    return result
  }

  FroalaEditor.DefineIcon('embed_dropdown', { NAME: 'Embed', PATH: plusBoxMultiplePath })
  FroalaEditor.RegisterCommand('embed_dropdown', {
    title: 'Embed',
    type: 'dropdown',
    focus: false,
    undo: false,
    pastePlain: true,
    refreshAfterCallback: true,
    options: getEmbedOptions(),
    callback(this, _: string, val: string) {
      setEditor(this)
      // eslint-disable-next-line react/no-this-in-sfc
      this.selection.save()
      switch (val) {
        case 'offer':
          if (venue) {
            nav.push(
              experiencesData?.results.length
                ? routes.manager2.marketing.oneTimeSMSCenter.smsBuilderFromScratch.offerModal
                : routes.manager2.marketing.oneTimeSMSCenter.smsBuilderFromScratch.noOfferModal,
              {
                params: { venueKey: venue.urlKey },
              }
            )
          }
          break
        case 'reservation':
          if (venueProfile?.reservationWidgetLink) {
            // eslint-disable-next-line react/no-this-in-sfc
            doLinkInsertion({ link: venueProfile.reservationWidgetLink, text: '<reservation_link>' })
          }
          break
        case 'menu':
          if (venueProfile?.menuLink) {
            // eslint-disable-next-line react/no-this-in-sfc
            doLinkInsertion({ link: venueProfile.menuLink, text: '<menu_link>' })
          }
          break
        default:
          break
      }
    },
  })

  const editorConfig = {
    ...defaultConfig,
    emoticonsUseImage: false,
    enter: FroalaEditor.ENTER_BR,
    toolbarBottom: false,
    useClasses: false,
    heightMin: 100,
    htmlAllowedAttrs: ['href', 'target'],
    htmlAllowedTags: ['a', 'br'],
    htmlSimpleAmpersand: true,
    linkAlwaysBlank: true,
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    linkText: false,
    placeholderText: formatMessage(smsBuilderMessages.messagePlaceholderText),
    pluginsEnabled: ['emoticons', 'charCounter', 'link', 'embedly'],
    toolbarButtons: venue?.isMMSEnabled
      ? ['emoticons', 'insertLink', 'embed_dropdown', 'code_dropdown']
      : ['insertLink', 'embed_dropdown', 'code_dropdown'],
    ...config,
    events: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      initialized(this: any) {
        setEditor(this)
        clearEditor.call(this)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      click(e: any) {
        e.preventDefault()
      },
      ...(config?.events || {}),
    },
  }

  const detectUntrackedLink = (val: string | null | undefined) => {
    if (!val || !URL_DETECT_REGEX.test(val.replace(A_TAG_DETECT_REGEX, ''))) {
      setIsUntrackedLinkTooltipShown(false)
      return
    }

    setIsUntrackedLinkTooltipShown(true)
  }

  return (
    <Box>
      {isUntrackedLinkTooltipShown && (
        <Tooltip
          key="link-warning"
          title={formatMessage(smsBuilderMessages.untrackedLinkWarningTitle)}
          content={<Text color="lightFont">{formatMessage(smsBuilderMessages.untrackedLinkWarningBody)}</Text>}
          onCloseComplete={() => {
            setIsUntrackedLinkTooltipShown(false)
          }}
          zIndexLayer="overlay"
          alignment="top"
          displayAction="click"
          useSmartAlignment={false}
          shouldCloseOnClick
          initiallyShow
        >
          <Box width="100%" />
        </Tooltip>
      )}

      <FormEditor
        config={editorConfig}
        field={field}
        venueProfile={venueProfile}
        data-test={dataTest}
        disabled={disabled}
        onChange={detectUntrackedLink}
        isLoyaltyAndPerksEnabled={venueSettings?.isLoyaltyAndPerksEnabled}
        referralProgramEnabled={venueSettings?.referralProgramEnabled}
      />
      {venue &&
        (experiencesData?.results.length ? (
          <Surface destination={routes.manager2.marketing.oneTimeSMSCenter.smsBuilderFromScratch.offerModal}>
            <Window>
              <Offer
                venue={venue}
                experiencesData={experiencesData}
                insertLink={doLinkInsertion}
                destination={destination}
                closeHref={nav.closeSurfaceHref(routes.manager2.marketing.oneTimeSMSCenter.smsBuilderFromScratch.offerModal, {
                  params: { venueKey: venue?.urlKey },
                })}
              />
            </Window>
          </Surface>
        ) : (
          <Surface destination={routes.manager2.marketing.oneTimeSMSCenter.smsBuilderFromScratch.noOfferModal}>
            <Window>
              <NoOffer
                closeHref={nav.closeSurfaceHref(routes.manager2.marketing.oneTimeSMSCenter.smsBuilderFromScratch.noOfferModal, {
                  params: { venueKey: venue?.urlKey },
                })}
                confirmationModalHref={routes.manager2.marketing.oneTimeSMSCenter.cancelSMSEditModal}
                venue={venue}
                isDirty={isDirty}
                setGoToOffers={setGoToOffers}
              />
            </Window>
          </Surface>
        ))}
    </Box>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function clearEditor(this: any) {
  const currentHtml = this.html.get()
  if (currentHtml === '') {
    this.html.set('')
  }
}
interface WrapperProps extends React.DOMAttributes<HTMLElement> {
  disable?: boolean
  width?: string
}

export const BodyContentWrapper = styled.div<Omit<WrapperProps, 'as'>>`
  .fr-wrapper {
    border: ${`1px solid ${theme.colors.borders}`} !important;
    border-bottom-width: 2px !important;
    overflow-y: hidden !important;
    border-radius: 0 !important;
  }
  .fr-toolbar.fr-top {
    border-top-left-radius: ${theme.borderRadius.s} !important;
    border-top-right-radius: ${theme.borderRadius.s} !important;
  }
  .fr-second-toolbar {
    border: 0 !important;
    min-height: 0 !important;
  }
  .fr-wrapper,
  .fr-view {
    border-radius: 0 !important;
  }
  .fr-view {
    ${props =>
      props.disable &&
      css`
        background-color: ${theme.colors.secondaryBackground} !important;
      `}
    border: 0;
    padding: 12px 16px 12px 16px;
    box-sizing: border-box;
    overflow: auto;
    ${props =>
      props.width &&
      css`
        width: ${props.width};
      `}
  }
  .fr-view table {
    margin-bottom: 0;
  }
  .fr-view table td {
    padding: 0;
  }
  .fr-view table td,
  .fr-view table th {
    border: none;
  }
`
