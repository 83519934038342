import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import { MarketingTemplatesCard } from '../MarketingTemplatesCard'
import { MarketingTemplatesCarousel } from '../MarketingTemplatesCarousel'
import ExperienceTemplates from './assets/config.json'
import { messages } from './experienceTemplatesGallery.locales'
import { GetTemplateImages } from './utils'

interface TemplatesGalleryProps {
  isExperienceTemplatesEnabled: boolean | undefined
  venueKey: string
}

export interface TemplateProps {
  templateName: string
  templateTeaser: string
  id: string
}

export interface ExperienceTemplateProps extends TemplateProps {
  offerName: string
  offerDescription: string
  offerImage: string
  paymentPolicy: string
  partySize: string
  startAndEndDates: string
  daysOfTheWeek: string
  shifts: string
  seatingAreas: string
  [key: string]: string
}

export function ExperienceTemplatesGallery({ isExperienceTemplatesEnabled, venueKey }: TemplatesGalleryProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  if (!isExperienceTemplatesEnabled) {
    return <></>
  }

  const templates: ExperienceTemplateProps[] = ExperienceTemplates
  const templateImages = GetTemplateImages()

  return (
    <VStack data-test="templates-carousel" spacing="m" pb="lm" pt="m">
      <VStack spacing="s">
        <HStack spacing="s">
          <Text textStyle="h2">{formatMessage(messages.createExperienceTitle)}</Text>
          <StatusLabel variant="success">{formatMessage(messages.statusLabelText)}</StatusLabel>
        </HStack>
        <Text textStyle="body1" color="secondaryFont">
          {formatMessage(messages.createExperienceDescription)}
        </Text>
      </VStack>
      <MarketingTemplatesCarousel>
        {templates.map(item => (
          <MarketingTemplatesCard
            templateName={item.templateName}
            templateTeaser={item.templateTeaser}
            templateImage={templateImages[item.offerImage as keyof typeof templateImages]}
            buttonText={formatMessage(messages.useTemplateButtonText)}
            onClick={() =>
              nav.push(routes.manager2.marketing.offers.createExperienceFromTemplate, {
                params: { venueKey, templateId: item.id },
              })
            }
            key={`template-${templates.indexOf(item)}}`}
          />
        ))}
      </MarketingTemplatesCarousel>
    </VStack>
  )
}
